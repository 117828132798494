import {
  SET_BESPAAR_CO2,
  SET_BESPAAR,
  SET_ENERGIELENING,
  SET_MINDER_ENERGIE,
  SET_ELECTRICITY,
  SET_GAS,
  SET_CO2,
  SET_PREMIES,
  SET_INVESTEER,
  SET_HEAT_LOSS_DATA,
  SET_AREA_DATA,
  SET_ACTUAL_EPC_LABEL,
  SET_VERKOOP_ELEC,
} from '../actions/resultActions'

const initialState = {
  investeer: {},
  area: {},
  energielening: 0,
  premies: {},
  minderEnergie: {},
  electricity: {},
  gas: {},
  verkoopElectricity: {},
  co2: {},
  bespaar: {},
  bespaarCo2: 0,
  heatLossData: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INVESTEER:
      return { ...state, investeer: action.payload }
    case SET_BESPAAR:
      return { ...state, bespaar: action.payload }
    case SET_BESPAAR_CO2:
      return { ...state, bespaarCo2: action.payload }
    case SET_ENERGIELENING:
      return { ...state, energielening: action.payload }
    case SET_MINDER_ENERGIE:
      return { ...state, minderEnergie: action.payload }
    case SET_ELECTRICITY:
      return { ...state, electricity: action.payload }
    case SET_GAS:
      return { ...state, gas: action.payload }
    case SET_VERKOOP_ELEC:
      return { ...state, verkoopElectricity: action.payload }
    case SET_CO2:
      return { ...state, co2: action.payload }
    case SET_PREMIES:
      return { ...state, premies: action.payload }
    case SET_HEAT_LOSS_DATA:
      return { ...state, heatLossData: action.payload }
    case SET_AREA_DATA:
      return { ...state, area: action.payload }
    case SET_ACTUAL_EPC_LABEL:
      return { ...state, actualEpcLabel: action.payload }
    default:
      return state
  }
}
