import React from "react";

import { classNames } from "../../utils/helpers";
import "./customButton.scss";

const CustomButton = (props) => {
  const { title, handleClick, selected, name } = props;

  const handleSelected = (e) => {
    handleClick(e);
  };
  return (
    <button
      aria-pressed={Boolean(selected)}
      type="button"
      onClick={(e) => handleSelected(e)}
      name={name || ""}
      className={
        classNames({ "button-default button-active": selected }) ||
        "button-default"
      }
    >
      {selected ? (
        <i className="icon-checkmark" aria-hidden="true" title="Check Icon"/>
      ) : (
        <span />
      )}
      <div className="button-title">{title}</div>
    </button>
  );
};
export default CustomButton;
