import React, { useEffect, useRef, useState } from 'react'
import { loadModules } from 'esri-loader'
import axios from 'axios'
import { APP_URL } from '../../utils/constants'
import { sessionPutCall } from '../../utils/apiActions'
import Cookies from 'js-cookie'

import './heatMap.scss'

const urlBase =
  'https://extags.qa.stad.gent/server/rest/services/EXT_CheckJeHuis/gevelisolatie/MapServer/4/'
const urlGebouwen =
  'https://extags.qa.stad.gent/server/rest/services/EXT_CheckJeHuis/gevelisolatie/MapServer/3/'
const urlNumber =
  'https://extags.qa.stad.gent/server/rest/services/EXT_CheckJeHuis/gevelisolatie/MapServer/2/'

const mapConfig = {
  center: [3.7252294866765903, 51.05451468609126],
  zoom: 14,
  maxZoom: 21,
  scale: 2500,
}

let searchWidget
let locatorTask
let view

export default function HeatMap(props) {
  const { layerUrl } = props
  const mapRef = useRef()
  const [state, setState] = useState({
    searchTerm: '',
    options: [],
    optionsDropdown: false,
    searchValue: '',
  })

  const popupContent = `<p>{address}, {Postal} {city}</p>`
  const template = {
    outFields: ['address',"Postal","city"],
    title: 'Adres',
    content: popupContent,
  }

  useEffect(() => {
    // lazy load the required ArcGIS API for JavaScript modules and CSS
    loadModules(
      [
        'esri/Map',
        'esri/views/MapView',
        'esri/layers/FeatureLayer',
        'esri/layers/WMTSLayer',
        'esri/layers/MapImageLayer',
        'esri/widgets/Search',
        'esri/widgets/Search/SearchSource',
        'esri/tasks/Locator',
        'esri/intl',
        'esri/core/urlUtils',
      ],
      { css: true },
    ).then(
      ([
        ArcGISMap,
        MapView,
        FeatureLayer,
        WMTSLayer,
        MapImageLayer,
        Search,
        SearchSource,
        Locator,
        intl,
        urlUtils,
      ]) => {
        intl.setLocale('nl')

        // urlUtils.addProxyRule({
        //   urlPrefix: "geo.gent.be",
        //   proxyUrl: "https://test6.develop.pdt.agifly.cloud/"
        // });
        // urlUtils.addProxyRule({
        //   urlPrefix: "extgis.gent.be",
        //   proxyUrl: "https://proxy-add-cors.develop.pdt.agifly.cloud/"
        // });

        // Set up a locator task using the world geocoding service
        locatorTask = new Locator({
          url:
            'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer',
        })
        const baseLayer = new WMTSLayer({
          url: 'https://geo.gent.be/geoserver/gwc/service/wmts',
          copyright: 'Gent',
          activeLayer: {
            id: 'SG-E-Stadsplan:Stadsplan',
          },
          maxScale: 0,
        })
        const labelClass = {
          // autocasts as new LabelClass()
          symbol: {
            type: 'text', // autocasts as new TextSymbol()
            color: '#23333a',
            font: {
              // autocast as new Font()
              family: 'Fira Sans',
              size: 8,
            },
          },
          labelPlacement: 'above-center',
          labelExpressionInfo: {
            expression: '$feature.HNRLABEL',
          },
          maxScale:0,
          minScale:3000
        }

        const featureLayer = new FeatureLayer({
          url: urlBase,
          maxScale: 0,
          // popupTemplate: template,
          opacity: 0.6,
        })
        const layerGebouwen = new FeatureLayer({
          url: urlGebouwen,
          maxScale: 0,
        })
        const layerNumber = new FeatureLayer({
          url: urlNumber,
          labelingInfo: [labelClass],
          opacity: 0,
          minScale: 3000,
          maxScale: 0,
        })
        const map = new ArcGISMap({
          layers: [baseLayer, featureLayer, layerNumber],
        })

        view = new MapView({
          container: mapRef.current,
          map,
          ...mapConfig,
        })

        searchWidget = new Search({
          sources: [
            {
              locator: new Locator({
                url:
                  'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer',
              }),
              countryCode: 'BE',
              singleLineFieldName: 'SingleLine',
              name: 'Custom Geocoding Service',
              localSearchOptions: {
                minScale: 300000,
                distance: 50000,
              },
              maxResults: 1,
              maxSuggestions: 6,
              suggestionsEnabled: false,
              popupTemplate: template,
              outFields: ['address', 'Postal', 'city'],
            },
          ],
          view,
          includeDefaultSources: false,
        })
        {
        }

        searchWidget.on('search-complete', function ({ searchTerm: value }) {
          setState((st) => ({ ...st, searchTerm: value }))
        })

        // Add the search widget to the top right corner of the view
        view.ui.add(searchWidget, {
          position: 'top-right',
        })

        view.popup.defaultPopupTemplateEnabled === true

        view.on('click', function (event) {
          const params = {
            location: event.mapPoint,
          }

          locatorTask
            .locationToAddress(params)
            .then(function (response) {
              setState((st) => ({ ...st, searchTerm: response.address }))
            })
            .catch(function (error) {
              // If the promise fails and no result is found, show a generic message
              console.log(error)
            })
        })

        axios.get(`${APP_URL}/session/${Cookies.get("userId")}`).then(
          ({
            data: {
              data: { session },
            },
          }) => {
            const { address } = session[0]
            setState((st) => ({ ...st, searchTerm: address }))
          },
        )

        return () => {
          if (view) {
            // destroy the map view
            view.destroy()
          }
        }
      },
    )
  }, [])

  useEffect(() => {
    if (searchWidget) {
      searchWidget.search(state.searchTerm)
    }

    sessionPutCall(Number(Cookies.get("userId")), {
      address: state.searchTerm,
    })
  }, [state.searchTerm])

  return (
    <div
      className="webmap"
      style={{ height: '55%', position: 'relative' }}
      ref={mapRef}
    >
      {/* <input
        type="search"
        required="true"
        id="header-search"
        name="header-search"
        autoComplete="off"
        value={state.searchValue}
        className="search inline map-search"
        onChange={handleInputChange}
        style={{
          position: 'absolute',
          right: 0,
          width: '250px',
          height: '40px',
          top: '12px',
          right: '10px',
        }}
      />
      <div
        className="suggestions"
        style={{
          backgroundColor: state.optionsDropdown ? 'white' : 'transparent',
        }}
      >
        {state.optionsDropdown &&
          state.options.map((title) => (
            <button
              type="button"
              key={title}
              onClick={() => {
                handleSuggestionClick(title)
              }}
            >
              {title}
            </button>
          ))}
      </div> */}
    </div>
  )
}
