import React from 'react'

export const epc = (
  <svg
    width="100%"
    height="40px"
    preserveAspectRatio="none"
    viewBox="0 0 624 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>atom/score/full/dt</title>
    <desc>Created with Sketch.</desc>
    <g
      id="atom/score/full/dt"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="atom/score/cell/mob">
        <path
          d="M2,32 L10,16 L2,0 L88,0 L96,16 L88,32 L2,32 Z"
          id="Combined-Shape"
          fill="#F20F36"
          fillRule="evenodd"
        />
        <text
          id="X"
          fontFamily="FiraSans-Regular, Fira Sans"
          fontSize="12"
          fontWeight="normal"
          letterSpacing="0.3333333"
          fill="#FFFFFF"
        >
          <tspan x="48.8873333" y="18">
            F
          </tspan>
        </text>
      </g>
      <g id="atom/score/cell/mob" transform="translate(88.000000, 0.000000)">
        <path
          d="M2,32 L10,16 L2,0 L88,0 L96,16 L88,32 L2,32 Z"
          id="Combined-Shape"
          fill="#F95706"
          fillRule="evenodd"
        />
        <text
          id="X"
          fontFamily="FiraSans-Regular, Fira Sans"
          fontSize="12"
          fontWeight="normal"
          letterSpacing="0.3333333"
          fill="#FFFFFF"
        >
          <tspan x="48.6233334" y="18">
            E
          </tspan>
        </text>
      </g>
      <g id="atom/score/cell/mob" transform="translate(176.000000, 0.000000)">
        <path
          d="M2,32 L10,16 L2,0 L88,0 L96,16 L88,32 L2,32 Z"
          id="Combined-Shape"
          fill="#FAB600"
          fillRule="evenodd"
        />
        <text
          id="X"
          fontFamily="FiraSans-Regular, Fira Sans"
          fontSize="12"
          fontWeight="normal"
          letterSpacing="0.3333333"
          fill="#FFFFFF"
        >
          <tspan x="47.9693333" y="18">
            D
          </tspan>
        </text>
      </g>
      <g id="atom/score/cell/mob" transform="translate(264.000000, 0.000000)">
        <path
          d="M2,32 L10,16 L2,0 L88,0 L96,16 L88,32 L2,32 Z"
          id="Combined-Shape"
          fill="#F5D605"
          fillRule="evenodd"
        />
        <text
          id="X"
          fontFamily="FiraSans-Regular, Fira Sans"
          fontSize="12"
          fontWeight="normal"
          letterSpacing="0.3333333"
          fill="#FFFFFF"
        >
          <tspan x="48.4733333" y="18">
            C
          </tspan>
        </text>
      </g>
      <g id="atom/score/cell/mob" transform="translate(352.000000, 0.000000)">
        <path
          d="M2,32 L10,16 L2,0 L88,0 L96,16 L88,32 L2,32 Z"
          id="Combined-Shape"
          fillOpacity="0.5"
          fill="#38AB30"
          fillRule="evenodd"
        />
        <text
          id="X"
          fontFamily="FiraSans-Regular, Fira Sans"
          fontSize="12"
          fontWeight="normal"
          letterSpacing="0.3333333"
          fill="#FFFFFF"
        >
          <tspan x="48.1853334" y="18">
            B
          </tspan>
        </text>
      </g>
      <g id="atom/score/cell/mob" transform="translate(440.000000, 0.000000)">
        <path
          d="M2,32 L10,16 L2,0 L88,0 L96,16 L88,32 L2,32 Z"
          id="Combined-Shape"
          fillOpacity="0.75"
          fill="#38AB30"
          fillRule="evenodd"
        />
        <text
          id="X"
          fontFamily="FiraSans-Regular, Fira Sans"
          fontSize="12"
          fontWeight="normal"
          letterSpacing="0.3333333"
          fill="#FFFFFF"
        >
          <tspan x="48.3953334" y="18">
            A
          </tspan>
        </text>
      </g>
      <g id="atom/score/cell/mob" transform="translate(528.000000, 0.000000)">
        <path
          d="M2,32 L10,16 L2,0 L88,0 L96,16 L88,32 L2,32 Z"
          id="Combined-Shape"
          fill="#38AB30"
          fillRule="evenodd"
        />
        <text
          id="X"
          fontFamily="FiraSans-Regular, Fira Sans"
          fontSize="12"
          fontWeight="normal"
          letterSpacing="0.3333333"
          fill="#FFFFFF"
        >
          <tspan x="45.2946667" y="18">
            A+
          </tspan>
        </text>
      </g>
    </g>
  </svg>
)

export const epcMobile = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="none"
    width="100%"
    height="32px"
    viewBox="0 0 316 32"
    version="1.1"
  >
    <title>atom/score/full/mob</title>
    <desc>Created with Sketch.</desc>
    <g
      id="atom/score/full/mob"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="atom/score/cell/mob">
        <path
          d="M2,32 L10,16 L2,0 L44,0 L52,16 L44,32 L2,32 Z"
          id="Combined-Shape"
          fill="#F20F36"
          fillRule="evenodd"
        />
        <text
          id="X"
          fontFamily="FiraSans-Regular, Fira Sans"
          fontSize="12"
          fontWeight="normal"
          letterSpacing="0.3333333"
          fill="#FFFFFF"
        >
          <tspan x="25.054" y="18">
            F
          </tspan>
        </text>
      </g>
      <g id="atom/score/cell/mob" transform="translate(44.000000, 0.000000)">
        <path
          d="M2,32 L10,16 L2,0 L44,0 L52,16 L44,32 L2,32 Z"
          id="Combined-Shape"
          fill="#F95706"
          fillRule="evenodd"
        />
        <text
          id="X"
          fontFamily="FiraSans-Regular, Fira Sans"
          fontSize="12"
          fontWeight="normal"
          letterSpacing="0.3333333"
          fill="#FFFFFF"
        >
          <tspan x="24.79" y="18">
            E
          </tspan>
        </text>
      </g>
      <g id="atom/score/cell/mob" transform="translate(88.000000, 0.000000)">
        <path
          d="M2,32 L10,16 L2,0 L44,0 L52,16 L44,32 L2,32 Z"
          id="Combined-Shape"
          fill="#FAB600"
          fillRule="evenodd"
        />
        <text
          id="X"
          fontFamily="FiraSans-Regular, Fira Sans"
          fontSize="12"
          fontWeight="normal"
          letterSpacing="0.3333333"
          fill="#FFFFFF"
        >
          <tspan x="24.136" y="18">
            D
          </tspan>
        </text>
      </g>
      <g id="atom/score/cell/mob" transform="translate(132.000000, 0.000000)">
        <path
          d="M2,32 L10,16 L2,0 L44,0 L52,16 L44,32 L2,32 Z"
          id="Combined-Shape"
          fill="#F5D605"
          fillRule="evenodd"
        />
        <text
          id="X"
          fontFamily="FiraSans-Regular, Fira Sans"
          fontSize="12"
          fontWeight="normal"
          letterSpacing="0.3333333"
          fill="#FFFFFF"
        >
          <tspan x="24.64" y="18">
            C
          </tspan>
        </text>
      </g>
      <g id="atom/score/cell/mob" transform="translate(176.000000, 0.000000)">
        <path
          d="M2,32 L10,16 L2,0 L44,0 L52,16 L44,32 L2,32 Z"
          id="Combined-Shape"
          fillOpacity="0.5"
          fill="#38AB30"
          fillRule="evenodd"
        />
        <text
          id="X"
          fontFamily="FiraSans-Regular, Fira Sans"
          fontSize="12"
          fontWeight="normal"
          letterSpacing="0.3333333"
          fill="#FFFFFF"
        >
          <tspan x="24.352" y="18">
            B
          </tspan>
        </text>
      </g>
      <g id="atom/score/cell/mob" transform="translate(220.000000, 0.000000)">
        <path
          d="M2,32 L10,16 L2,0 L44,0 L52,16 L44,32 L2,32 Z"
          id="Combined-Shape"
          fillOpacity="0.75"
          fill="#38AB30"
          fillRule="evenodd"
        />
        <text
          id="X"
          fontFamily="FiraSans-Regular, Fira Sans"
          fontSize="12"
          fontWeight="normal"
          letterSpacing="0.3333333"
          fill="#FFFFFF"
        >
          <tspan x="24.562" y="18">
            A
          </tspan>
        </text>
      </g>
      <g id="atom/score/cell/mob" transform="translate(264.000000, 0.000000)">
        <path
          d="M2,32 L10,16 L2,0 L44,0 L52,16 L44,32 L2,32 Z"
          id="Combined-Shape"
          fill="#38AB30"
          fillRule="evenodd"
        />
        <text
          id="X"
          fontFamily="FiraSans-Regular, Fira Sans"
          fontSize="12"
          fontWeight="normal"
          letterSpacing="0.3333333"
          fill="#FFFFFF"
        >
          <tspan x="21.4613334" y="18">
            A+
          </tspan>
        </text>
      </g>
    </g>
  </svg>
)
