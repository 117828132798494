import React from 'react'

import { classNames } from '../../utils/helpers'
import './customCard.scss'

const CustomCard = (props) => {
  // const [selected, setSelected] = useState(false)
  const { description, handleClick, image, selected } = props
  const handleSelected = () => {
    // setSelected(!selected)
    handleClick && handleClick()
  }

  return (
    <div className="card-container">
      <button
        type="button"
        onClick={handleSelected}
        aria-pressed={Boolean(selected).toString()}
        className={
          classNames({ 'tag-custom-default tag-custom-active': selected }) ||
          'tag-custom-default'
        }
      >
        <div className="icon-wrapper">
          {selected ? (
            <i
              className="icon-checkmark"
              aria-hidden="true"
              title="Check Icon"
            />
          ) : (
            <span />
          )}
        </div>
        <div className="card-image">{image}</div>
        <div className="description">{description}</div>
      </button>
    </div>
  )
}

export default CustomCard
