import React from 'react'
import CustomCard from '../../../components/customCard'
import MoreInfoButton from '../../../components/moreInfoButton'
import { Images } from '../../../utils/constants'
import { getHiddenTitle } from '../../../utils/helpers'
import CommonLeftSection from '../commonLeftSection'

const CommonCardAnswers = ({
  pageNo,
  handleNextStep,
  getSelected,
  pageData,
  answerSetKey = 'page',
  image,
  modalData,
  leftSectionImage,
  progressValue,
  imageTitle,
  extraComponent,
}) => {
  const { question = '', description = '', answers = '' } = pageData[
    answerSetKey
  ]
  return (
    <div className="questionnaire-inner">
      <div className="inner-left">
        <CommonLeftSection
          metaTitle={getHiddenTitle(pageNo)}
          title={question}
          leftSectionImage={leftSectionImage}
          introduction={description}
          modalData={modalData}
          answerSetKey={answerSetKey}
          progressValue={progressValue}
          imageTitle={imageTitle}
        />
      </div>
      <div>
        <ul className="inner-right">
          {answers.map((answer, index) => (
            <li
              key={answer.title}
              style={{
                display:
                  answerSetKey === 'elektriciteit' && index === 1
                    ? 'none'
                    : 'initial',
              }}
            >
              <CustomCard
                selected={getSelected(index)}
                description={answer.title}
                handleClick={() => handleNextStep(index, answer.title)}
                image={Images[image[index]]}
              />
            </li>
          ))}
        </ul>
        {extraComponent && extraComponent()}
      </div>
      <hr />
      <MoreInfoButton phone modalData={modalData} answerSetKey={answerSetKey} />
    </div>
  )
}
export default CommonCardAnswers
