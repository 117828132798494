import { Formik } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'

import MoreInfoButton from '../../../components/moreInfoButton'
import { getPage26Responsese } from '../../../utils/apiActions'
import { pageStaticData } from '../../../utils/constants'
import { getHiddenTitle } from '../../../utils/helpers'
import CommonLeftSection from '../commonLeftSection'

import './styles.scss'

function validateValue(value, min, max) {
  const number = Number.parseInt(value, 10)
  return !!(number < min || number > max || number === ',' || value === '')
}

function validateValue23(value, min, max) {
  if (value === '') return false
  const number = Number.parseInt(value, 10)
  if (number < min || number > max || number === ',') {
    return true
  }
  return false
}

function isEnabled(values, min, max) {
  return Object.values(values).every((val) => !validateValue(val, min, max))
}

function filterKey(item, pageNo, pages) {
  if (
    (pages?.[22]?.answerNo !== 0 || pages?.[25]?.answerNo !== 0) &&
    item.key === 'energieproductie'
  ) {
    return false
  }

  return true
}

function getErrorMessage(pageNo, key) {
  switch (pageNo) {
    case '23':
      return pageStaticData[23].errorMessage
    case '26':
      return pageStaticData[26][key]
        ? pageStaticData[26][key].errorMessage
        : pageStaticData[26].general.errorMessage
    default:
      return pageStaticData[26].general
  }
}

function roundedValue(value) {
  return value !== '' ? Math.round(Number.parseInt(value, 10)) : ''
}

const CommonInputAnswers = ({
  pageNo,
  pageData,
  handleNextStep,
  answerSetKey = 'page',
  min,
  max,
  hasCameraImage,
  modalData,
}) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({})
  const { question, description, input } = pageData[answerSetKey]
  const pages = useSelector(({ questionnaire }) => questionnaire.pages)
  const [validation, setValidation] = useState(false)

  const filteredInputs = useMemo(
    () => input.filter((item) => filterKey(item, pageNo, pages)),
    [input, pageNo, pages],
  )

  const onFocus = () => {
    setValidation(true)
  }

  const onBlur = () => {
    setValidation(false)
  }

  const isMobileView = window.innerWidth < 768

  const setResponse = useCallback(async () => {

    setState(await getPage26Responsese(pages, Number(Cookies.get('userId')), dispatch))

  }, [parseInt(Cookies.get('userId')), pages])

  const initialValues = useMemo(
    () => (pageNo === '26' ? state : pages[pageNo]),
    [pageNo, state, pages],
  )

  const page26Validation = (values, key) =>
    key === 'energieproductie'
      ? validateValue(values[key], 0, 100)
      : validateValue(values[key], min, max)

  const inputLabel = (title) =>
    pages[6]?.answerNo === 3
      ? title.replace('Aardgasverbruik', 'Warmteverbruik')
      : title

  useEffect(() => {
    if (pageNo === '26') {
      const firstTime = Number(localStorage.getItem('firstTime26'))
      if(firstTime != 1){
        setResponse()
      }else{
        const elektriciteitsverbruik = localStorage.getItem('elektriciteitsverbruik')
        const aardgasverbruik = localStorage.getItem('aardgasverbruik')
        const zonnepanelen = localStorage.getItem('zonnepanelen')
        const energieproductie = localStorage.getItem('energieproductie')
        setState({
          elektriciteitsverbruik: elektriciteitsverbruik,
          aardgasverbruik: Math.round(aardgasverbruik),
          zonnepanelen: Math.round(zonnepanelen),
          energieproductie: Math.round(energieproductie),
        })
      }
    }
  }, [pageNo])



  return (
    <Formik initialValues={initialValues} enableReinitialize>
      {({ values, handleChange }) => (
        <div
          className="questionnaire-inner"
          button-hidden={Boolean(validation).toString()}
        >
          <div className="inner-left">
            <CommonLeftSection
              metaTitle={getHiddenTitle(pageNo)}
              title={question}
              introduction={description}
              hasImage={hasCameraImage}
              modalData={modalData}
              answerSetKey={answerSetKey}
            />
          </div>
          <div className="inner-right right-side-input">
            <div>
              {filteredInputs.map((item) => {
                return (
                  <div key={item.key} className={`form-columns ${item.key == "energieproductie" ? 'hidden' : '' }`}>
                    <label htmlFor={item.key}>{inputLabel(item.title)}</label>
                    <div className="form-item-column number-input-style">
                      <input
                        type="number"
                        id={item.key}
                        name={item.key}
                        className="number"
                        value={roundedValue(values[item.key])}
                        onChange={handleChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                      />
                      {(pageNo === '23'
                        ? validateValue23(values[item.key], min, max)
                        : page26Validation(values, item.key)) && (
                        <div className="form-item">
                          <div
                            className="field-message error"
                            role="alert"
                            id="aardgasverbruik-aardgasverbruik-message"
                          >
                            {getErrorMessage(pageNo, item.key)}
                            <div className="accolade " />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="form-item-column" />
                  </div>
                )
              })}
              <button
                onClick={() => handleNextStep(values)}
                type="button"
                className="button button-primary button-large"
                disabled={!isEnabled(values, min, max)}
              >
                Volgende
              </button>
            </div>
          </div>
          {validation && isMobileView ? null : (
            <>
              <hr />
              <MoreInfoButton
                phone
                modalData={modalData}
                answerSetKey={answerSetKey}
              />
            </>
          )}
        </div>
      )}
    </Formik>
  )
}

export default CommonInputAnswers
