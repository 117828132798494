import React from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'

import Cookies from 'js-cookie';
import {
  getQuestionKey,
  getSelectedIndexPage11,
  getSelectedIndexPage12,
  setPageTitle,
  appendRoute,
  getImage,
} from '../../utils/helpers'
import { pageTitles } from '../../utils/constants'
import CommonCardAnswers from './commonCardAnswers'
import CommonButtonAnswers from './commonButtonAnswers'
import CommonInputAnswers from './commonInputAnswers'
import CommonCardWithInputAnswers from './commonCardWithInputAnswers'
import { HoofdakExtra } from '../../components/questionnaireExtra'
import { answerPostCall } from '../../utils/apiActions'

function ProtectedRoute({ component: Component, pages, ...rest }) {
  // if (!Object.keys(pages[1]).length) {
  //   return <Redirect to="/questionnaire/1" />
  // }

  if (pages[1].answerNo === 3) {
    return <Redirect to="/adviesappartementen" />
  }
  return <Component {...rest} />
}

export default function Pages({
  pages,
  setStep,
  questionnaireLableData,
  modalLabelData,
}) {
  const { pageNo } = useParams()
  const history = useHistory()
  const userId = Number(Cookies.get("userId"))

  function handleNextStepPageSeven(questionKey, customData = {}) {
    return (answerNo, key) => {
      answerPostCall(userId, questionKey, key)
      setStep({
        pageNo,
        data: { answerNo, [questionKey]: key, ...customData },
      })
      if (
        (pages[6].answerNo === 0 || pages[6].answerNo === 2) &&
        answerNo === 0
      ) {
        history.push({
          pathname: appendRoute(Number.parseInt(pageNo, 10) + 2, answerNo),
          state: { fromPage: pageNo },
        })
      } else if (pages[6].answerNo === 1 && answerNo === 0) {
        history.push({
          pathname: appendRoute(Number.parseInt(pageNo, 10) + 2, answerNo),
          state: { fromPage: pageNo },
        })
      } else if (
        pages[6].answerNo === 3 &&
        (answerNo === 1 || answerNo === 2)
      ) {
        history.push({
          pathname: appendRoute(Number.parseInt(pageNo, 10) + 2, answerNo),
          state: { fromPage: pageNo },
        })
      } else {
        history.push({
          pathname: appendRoute(Number.parseInt(pageNo, 10) + 1, answerNo),
          state: { fromPage: pageNo },
        })
      }
    }
  }

  // Common handler on card click
  function commonHandleNextStep(questionKey, customData = {}) {
    return (answerNo, key) => {
      answerPostCall(userId, questionKey, key)
      setStep({
        pageNo,
        data: { answerNo, [questionKey]: key, ...customData },
      })

      history.push({
        pathname: appendRoute(Number.parseInt(pageNo, 10) + 1, answerNo),
        state: { fromPage: pageNo },
      })
    }
  }

  // Next on card click dynamic logic
  const handleNextDynamic = (questionKey, page) => {
    return (answerNo, key) => {
      answerPostCall(userId, questionKey, key)
      setStep({
        pageNo,
        data: { answerNo, [questionKey]: key },
      })
      const pageTwentyTwo =
        answerNo === 0
          ? Number.parseInt(pageNo, 10) + 1
          : Number.parseInt(pageNo, 10) + 3
      const pageTwentyEight =
        answerNo !== 0
          ? Number.parseInt(pageNo, 10) + 1
          : Number.parseInt(pageNo, 10) + 3
      const pageEighteen =
        pages[1].answerNo === 0 || pages[1].answerNo === 1
          ? Number.parseInt(pageNo, 10) + 1
          : Number.parseInt(pageNo, 10) + 2

      const pageNumber = Number.parseInt(page, 10)
      if (pageNumber === 22) {
        history.push({
          pathname: appendRoute(Number(pageTwentyTwo), pages[6]?.answerNo),
          state: { fromPage: pageNo },
        })
      } else if (pageNumber === 18) {
        history.push({
          pathname: appendRoute(Number(pageEighteen), pages[6]?.answerNo),
          state: { fromPage: pageNo },
        })
      } else if (pageNumber === 13) {
        history.push({
          pathname: appendRoute(Number(pageNo) + 2, pages[6]?.answerNo),
          state: { fromPage: pageNo },
        })
      } else if (pageNumber === 28) {
        history.push({
          pathname: appendRoute(Number(pageTwentyEight), pages[6]?.answerNo),
          state: { fromPage: pageNo },
        })
      } else {
        history.push({
          pathname: `/advies`,
          state: { fromPage: pageNo },
        })
      }
    }
  }

  // Handle next page logic on button click in questionnaire pages
  const handleNextOnButton = (questionKey, page) => (values) => {
    answerPostCall(userId, questionKey, JSON.stringify(values))
    setStep({ pageNo, data: { ...values } })
    const pageNumber = Number.parseInt(page, 10)
    if (pageNumber === 23) {
      history.push({
        pathname: appendRoute(
          Number.parseInt(pageNo, 10) + 2,
          pages[6]?.answerNo,
        ),
        state: { fromPage: pageNo },
      })
    } else {
      history.push({
        pathname: appendRoute(Number(pageNo) + 1, pages[6]?.answerNo),
        state: { fromPage: pageNo },
      })
    }
  }

  const getSelectedPageNo = (dependentPage, getSelectedIndexPage) => {
    return (index) => {
      const storedSelection = pages[pageNo]?.answerNo === index
      const previousSelection =
        getSelectedIndexPage(pages[dependentPage].answerNo) === index
      return pages[pageNo]?.answerNo >= 0 ? storedSelection : previousSelection
    }
  }

  const getSelectedBasedOnPage2 = (index) => {
    const storedSelection = pages[pageNo]?.answerNo === index
    const previousSelection =
      (pages[2].answerNo === 3 || pages[2].answerNo === 4) && index === 2
    return pages[pageNo]?.answerNo >= 0 ? storedSelection : previousSelection
  }

  setPageTitle(pageTitles[`questionnaire${pageNo}`])
  switch (pageNo) {
    case '1':
      return (
        <CommonCardAnswers
          getSelected={(index) => pages[pageNo]?.answerNo === index}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          image={['step1Image1', 'step1Image2', 'step1Image3', 'step1Image4']}
          pages={pages}
          modalData={modalLabelData[pageNo]}
          leftSectionImage="wonningImage"
          progressValue={20}
          imageTitle="Woning"
        />
      )
    case '2':
      return (
        <ProtectedRoute
          component={CommonButtonAnswers}
          getSelected={(index) => pages[pageNo]?.answerNo === index}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '3':
      return (
        <ProtectedRoute
          pages={pages}
          component={CommonCardWithInputAnswers}
          pageData={questionnaireLableData[pageNo]}
          pageNo={pageNo}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo), {
            oppervlakte_input: 0,
          })}
          handleButtonNext={handleNextOnButton(getQuestionKey(pageNo))}
          min={0}
          max={10000}
          getSelected={(index) =>
            pages[pageNo]?.oppervlakte_input
              ? false
              : pages[pageNo]?.answerNo === index
          }
          image={['step3Image1', 'step3Image2', 'step3Image3']}
          modalData={modalLabelData[pageNo]}
          leftSectionImage="wonningImage"
          progressValue={20}
          imageTitle="Woning"
        />
      )
    case '4':
      return (
        <ProtectedRoute
          component={CommonButtonAnswers}
          getSelected={(index) => pages[pageNo]?.answerNo === index}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '5':
      return (
        <ProtectedRoute
          component={CommonButtonAnswers}
          getSelected={(index) => pages[pageNo]?.answerNo === index}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '6':
      return (
        <ProtectedRoute
          component={CommonCardAnswers}
          getSelected={(index) => pages[pageNo]?.answerNo === index}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="verwarmingImage"
          progressValue={40}
          imageTitle="Verwarming & warm water"
          image={['step6Image1', 'step6Image2', 'step6Image3', 'step6Image4']}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '7':
      switch (pages[pageNo - 1]?.answerNo) {
        case 0:
          setPageTitle('Gas | Gent: Check je huis')
          return (
            <ProtectedRoute
              pages={pages}
              component={CommonCardAnswers}
              metaSelection={0}
              pageData={questionnaireLableData[pageNo]}
              pageNo={pageNo}
              getSelected={(index) =>
                pages[pageNo]?.answerNo >= 0
                  ? pages[pageNo]?.answerNo === index
                  : index === 2
              }
              handleNextStep={handleNextStepPageSeven(
                pages[6].answerNo === 0 ? 'gas' : 'stookolie',
              )}
              answerSetKey="gas"
              modalData={modalLabelData[pageNo]}
              leftSectionImage="verwarmingImage"
              progressValue={40}
              imageTitle="Verwarming & warm water"
              image={[
                'stepGasImage1',
                'stepGasImage2',
                'stepGasImage3',
                'stepGasImage4',
              ]}
            />
          )
        case 1:
          setPageTitle('Elektriciteit | Gent: Check je huis')
          return (
            <ProtectedRoute
              pages={pages}
              component={CommonCardAnswers}
              pageData={questionnaireLableData[pageNo]}
              pageNo={pageNo}
              metaSelection={1}
              handleNextStep={handleNextStepPageSeven('elektriciteit')}
              answerSetKey="elektriciteit"
              getSelected={(index) =>
                pages[pageNo]?.answerNo > 0
                  ? pages[pageNo]?.answerNo === index
                  : index === 0
              }
              leftSectionImage="verwarmingImage"
              progressValue={40}
              imageTitle="Verwarming & warm water"
              image={[
                'pageElecriciteImage1',
                'pageElecriciteImage2',
                'pageElecriciteImage3',
                'pageElecriciteImage4',
                'pageElecriciteImage5',
                'pageElecriciteImage6',
              ]}
              modalData={modalLabelData[pageNo]}
            />
          )

        case 2:
          setPageTitle('Stookolie | Gent: Check je huis')
          return (
            <ProtectedRoute
              pages={pages}
              component={CommonCardAnswers}
              metaSelection={2}
              pageData={questionnaireLableData[pageNo]}
              pageNo={pageNo}
              getSelected={(index) =>
                pages[pageNo]?.answerNo >= 0
                  ? pages[pageNo]?.answerNo === index
                  : index === 2
              }
              handleNextStep={handleNextStepPageSeven(
                pages[6].answerNo === 0 ? 'gas' : 'stookolie',
              )}
              answerSetKey="stookolie"
              leftSectionImage="verwarmingImage"
              progressValue={40}
              imageTitle="Verwarming & warm water"
              image={[
                'stepGasImage1',
                'stepGasImage2',
                'stepGasImage3',
                'stepGasImage4',
              ]}
              modalData={modalLabelData[pageNo]}
            />
          )
        case 3:
          setPageTitle('Een andere bron | Gent: Check je huis')
          return (
            <ProtectedRoute
              pages={pages}
              component={CommonCardAnswers}
              pageData={questionnaireLableData[pageNo]}
              pageNo={pageNo}
              handleNextStep={handleNextStepPageSeven('ean_andere_bron')}
              answerSetKey="Een andere bron"
              metaSelection={3}
              getSelected={(index) =>
                pages[pageNo]?.answerNo >= 0
                  ? pages[pageNo]?.answerNo === index
                  : index === 2
              }
              leftSectionImage="verwarmingImage"
              progressValue={40}
              imageTitle="Verwarming & warm water"
              image={[
                'pageAndreBronImage1',
                'pageAndreBronImage2',
                'pageAndreBronImage3',
              ]}
              modalData={modalLabelData[pageNo]}
            />
          )
        default:
          return (
            <ProtectedRoute
              pages={pages}
              component={CommonButtonAnswers}
              metaSelection={0}
              pageData={questionnaireLableData[pageNo]}
              pageNo={pageNo}
              getSelected={(index) =>
                pages[pageNo]?.answerNo >= 0
                  ? pages[pageNo]?.answerNo === index
                  : index === 2
              }
              handleNextStep={handleNextStepPageSeven(
                pages[6].answerNo === 0 ? 'gas' : 'stookolie',
              )}
              answerSetKey="gas"
              leftSectionImage="verwarmingImage"
              progressValue={40}
              imageTitle="Verwarming & warm water"
              image={[
                'stepGasImage1',
                'stepGasImage2',
                'stepGasImage3',
                'stepGasImage4',
              ]}
              modalData={modalLabelData[pageNo]}
            />
          )
      }
    case '8':
      return (
        <ProtectedRoute
          component={CommonCardAnswers}
          getSelected={(index) => pages[pageNo]?.answerNo === index}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="verwarmingImage"
          progressValue={40}
          imageTitle="Verwarming & warm water"
          image={['step8Image1', 'step8Image2', 'step8Image3', 'step8Image4']}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '9':
      return (
        <ProtectedRoute
          component={CommonButtonAnswers}
          getSelected={(index) => pages[pageNo]?.answerNo === index}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '10':
      return (
        <ProtectedRoute
          component={CommonButtonAnswers}
          getSelected={(index) => pages[pageNo]?.answerNo === index}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '11':
      return (
        <ProtectedRoute
          getSelected={getSelectedPageNo(6, getSelectedIndexPage11)}
          component={CommonCardAnswers}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="verwarmingImage"
          progressValue={40}
          imageTitle="Verwarming & warm water"
          image={[
            getImage(pages?.[6].answerNo),
            'step11Image2',
            'step11Image3',
            'step11Image4',
            'step11Image5',
          ]}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '12':
      return (
        <ProtectedRoute
          getSelected={getSelectedPageNo(2, getSelectedIndexPage12)}
          component={CommonCardAnswers}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="isolatieImage"
          progressValue={60}
          imageTitle="Isolatie & ventilatie"
          image={[
            'step12Image1',
            'step12Image2',
            'step12Image3',
            'step12Image4',
          ]}
          modalData={modalLabelData[pageNo]}
          extraComponent={HoofdakExtra}
        />
      )
    case '13':
      return (
        <ProtectedRoute
          getSelected={getSelectedPageNo(2, getSelectedIndexPage12)}
          component={CommonCardAnswers}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={handleNextDynamic(getQuestionKey(pageNo), pageNo)}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="isolatieImage"
          progressValue={60}
          imageTitle="Isolatie & ventilatie"
          image={[
            'step13Image1',
            'step13Image2',
            'step13Image3',
            'step13Image4',
            'step3Image1',
          ]}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '14':
      return (
        <ProtectedRoute
          component={CommonCardAnswers}
          getSelected={(index) => pages[pageNo]?.answerNo === index}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="isolatieImage"
          progressValue={60}
          imageTitle="Isolatie & ventilatie"
          image={['step14Image1', 'step14Image2', 'step14Image3']}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '15':
      return (
        <ProtectedRoute
          getSelected={getSelectedPageNo(2, getSelectedIndexPage12)}
          component={CommonCardAnswers}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="isolatieImage"
          progressValue={60}
          imageTitle="Isolatie & ventilatie"
          image={[
            'step15Image1',
            'step15Image2',
            'step15Image3',
            'step15Image4',
          ]}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '16':
      return (
        <ProtectedRoute
          getSelected={getSelectedPageNo(2, getSelectedIndexPage12)}
          component={CommonCardAnswers}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="isolatieImage"
          progressValue={60}
          imageTitle="Isolatie & ventilatie"
          image={[
            'step15Image1',
            'step15Image2',
            'step15Image3',
            'step15Image4',
          ]}
          modalData={modalLabelData[pageNo]}
        />
      )

    case '17':
      return (
        <ProtectedRoute
          getSelected={getSelectedBasedOnPage2}
          component={CommonCardAnswers}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="isolatieImage"
          progressValue={60}
          imageTitle="Isolatie & ventilatie"
          image={['step17Image1', 'step17Image2', 'step17Image3']}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '18':
      return (
        <ProtectedRoute
          getSelected={getSelectedBasedOnPage2}
          component={CommonCardAnswers}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={handleNextDynamic(getQuestionKey(pageNo), pageNo)}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="isolatieImage"
          progressValue={60}
          imageTitle="Isolatie & ventilatie"
          image={['step18Image1', 'step18Image2', 'step18Image3']}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '19':
      return (
        <ProtectedRoute
          getSelected={getSelectedBasedOnPage2}
          component={CommonCardAnswers}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="isolatieImage"
          progressValue={60}
          imageTitle="Isolatie & ventilatie"
          image={['step19Image1', 'step19Image2', 'step19Image3']}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '20':
      return (
        <ProtectedRoute
          component={CommonCardAnswers}
          getSelected={(index) =>
            pages[pageNo]?.answerNo >= 0
              ? pages[pageNo]?.answerNo === index
              : index === 1
          }
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="isolatieImage"
          progressValue={60}
          imageTitle="Isolatie & ventilatie"
          image={['step20Image1', 'step20Image2', 'step20Image3']}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '21':
      return (
        <ProtectedRoute
          component={CommonCardAnswers}
          getSelected={(index) =>
            pages[pageNo]?.answerNo >= 0
              ? pages[pageNo]?.answerNo === index
              : index === 0
          }
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="isolatieImage"
          progressValue={60}
          imageTitle="Isolatie & ventilatie"
          image={[
            'step21Image1',
            'step21Image2',
            'step21Image3',
            'step21Image4',
            'step21Image5',
          ]}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '22':
      return (
        <ProtectedRoute
          component={CommonCardAnswers}
          getSelected={(index) => pages[pageNo]?.answerNo === index}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={handleNextDynamic(getQuestionKey(pageNo), pageNo)}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="energyImage"
          progressValue={80}
          imageTitle="Zonnepanelen & energie"
          image={['step22Image1', 'step22Image2']}
          modalData={modalLabelData[pageNo]}
        />
      )

    case '23':
      return (
        <ProtectedRoute
          component={CommonInputAnswers}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={handleNextOnButton(getQuestionKey(pageNo), pageNo)}
          pageNo={pageNo}
          pages={pages}
          min={1}
          max={100}
          hasCameraImage
          modalData={modalLabelData[pageNo]}
        />
      )

    case '25':
      return (
        <ProtectedRoute
          component={CommonCardAnswers}
          getSelected={(index) => pages[pageNo]?.answerNo === index}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="energyImage"
          progressValue={80}
          imageTitle="Zonnepanelen & energie"
          image={['step25Image2', 'step25Image1']}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '26':
      return (
        <ProtectedRoute
          component={CommonInputAnswers}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={handleNextOnButton(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          min={0}
          max={100000}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '27':
      return (
        <ProtectedRoute
          component={CommonCardAnswers}
          getSelected={(index) => pages[pageNo]?.answerNo === index}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="climateImage"
          progressValue={100}
          imageTitle="Klimaat"
          image={['step27Image1', 'step27Image2']}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '28':
      return (
        <ProtectedRoute
          component={CommonCardAnswers}
          getSelected={(index) => pages[pageNo]?.answerNo === index}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={handleNextDynamic(getQuestionKey(pageNo), pageNo)}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="climateImage"
          progressValue={100}
          imageTitle="Klimaat"
          image={[
            'step28Image1',
            'step28Image2',
            'step28Image3',
            'step28Image4',
          ]}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '29':
      return (
        <ProtectedRoute
          component={CommonCardAnswers}
          getSelected={(index) => pages[pageNo]?.answerNo === index}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="climateImage"
          progressValue={100}
          imageTitle="Klimaat"
          image={['step29Image1', 'step29Image2']}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '30':
      return (
        <ProtectedRoute
          component={CommonCardAnswers}
          getSelected={(index) => pages[pageNo]?.answerNo === index}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="climateImage"
          progressValue={100}
          imageTitle="Klimaat"
          image={['step30Image1', 'step30Image2']}
          modalData={modalLabelData[pageNo]}
        />
      )
    case '31':
      return (
        <ProtectedRoute
          component={CommonCardAnswers}
          getSelected={(index) => pages[pageNo]?.answerNo === index}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={handleNextDynamic(getQuestionKey(pageNo), pageNo)}
          pageNo={pageNo}
          pages={pages}
          leftSectionImage="climateImage"
          progressValue={100}
          imageTitle="Klimaat"
          image={['step31Image1', 'step31Image2']}
          modalData={modalLabelData[pageNo]}
        />
      )

    default:
      return (
        <CommonCardAnswers
          getSelected={(index) => pages[pageNo]?.answerNo === index}
          pageData={questionnaireLableData[pageNo]}
          handleNextStep={commonHandleNextStep(getQuestionKey(pageNo))}
          pageNo={pageNo}
          modalData={modalLabelData[pageNo]}
        />
      )
  }
}
