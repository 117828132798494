import axios from 'axios'
import { APP_URL } from '../../utils/constants'

import {
  parseAdvice,
  parseHomeJson,
  parseMeerInfoModal,
  parsePageNotFound,
  parseQuestionnaire,
  parserResult,
} from '../../utils/helpers'

export const SET_TEXT = 'SET_TEXT'
export const SET_IS_LAODING = 'SET_IS_LAODING'

function getObject(arr = []) {
  return arr.reduce((prevVal, currentVal) => {
    return { ...prevVal, [currentVal.key]: currentVal.value }
  }, {})
}

export function appText(payload) {
  return {
    type: SET_TEXT,
    payload,
  }
}

export function setIsLoading(payload) {
  return {
    type: SET_IS_LAODING,
    payload,
  }
}

export function setAppText() {
  return (dispatch) => {
    dispatch(setIsLoading(true))
    axios
      .all([
        axios.get(`${APP_URL}/page/1`),
        axios.get(`${APP_URL}/page/2`),
        axios.get(`${APP_URL}/page/3`),
        axios.get(`${APP_URL}/page/4`),
        axios.get(`${APP_URL}/page/5`),
        axios.get(`${APP_URL}/page/6`),
        axios.get(`${APP_URL}/page/7`),
        axios.get(`${APP_URL}/page/8`),
        axios.get(`${APP_URL}/page/10`),
        axios.get(`${APP_URL}/page/11`),
      ])
      .then((response) => {
        const dataArr = response.map((data) =>
          getObject(data.data[0].pageContent),
        )
        dispatch(
          appText({
            home: parseHomeJson(dataArr[0]),
            result: parserResult(dataArr[1]),
            questionnaire: parseQuestionnaire({ ...dataArr[2], ...dataArr[3] }),
            advice: parseAdvice(dataArr[4]),
            not_found: parsePageNotFound(dataArr[5]),
            modal_meer_info: parseMeerInfoModal(dataArr[6]),
            footer: dataArr[7],
            pdf: dataArr[8],
            modal: dataArr[9],
          }),
        )
        dispatch(setIsLoading(false))
      })
  }
}
