import React from 'react'

import './styles.scss'

const InfoCard = ({ infoCard = '' }) => {
  return (
    <section className="highlight highlight--left checklist">
      <div
        className="highlight__inner"
        dangerouslySetInnerHTML={{ __html: infoCard }}
      />
    </section>
  )
}
export default InfoCard
