import React from 'react'
import { footerImage } from '../../images/svg'

const Footer = ({ labelsData = {} }) => {
  return (
    <footer>
      <div className="container">
        <section className="footer">
          <h2 className="visually-hidden">Footer</h2>
          <div className="footer-top">
            <div className="content-container">
              <div className="footer--column">
                <div className="gentinfo-block">
                  <figure className="gentinfo-logo-custom">
                    {footerImage}
                  </figure>
                  <div className="gentinfo-block-content">
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: labelsData.pdf_footer_contact_title,
                      }}
                    />
                    <ul className="icon-list ">
                      <li
                        dangerouslySetInnerHTML={{
                          __html: `<i
                          class="span-icon icon-phone"
                          aria-hidden="true"
                          title="Phone"
                        ></i>${labelsData.pdf_footer_contact_no}`,
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html: `<i
                          class="span-icon icon-envelope"
                          aria-hidden="true"
                          title="Envelope"
                          ></i>${labelsData.pdf_footer_contact_email}`,
                        }}
                      />

                      <li
                        dangerouslySetInnerHTML={{
                          __html: `<i
                          class="span-icon icon-url"
                          aria-hidden="true"
                          title="Icon URL"
                          ></i>${labelsData.pdf_footer_contact_url}`,
                        }}
                      />
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer--column text-container">
                <div
                  className="dg-block-post-address"
                  dangerouslySetInnerHTML={{
                    __html: labelsData.pdf_footer_text,
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </footer>
  )
}
export default Footer
