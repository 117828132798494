import React from 'react'
import CustomButton from '../../../components/customButton'
import MoreInfoButton from '../../../components/moreInfoButton'
import { getHiddenTitle } from '../../../utils/helpers'
import CommonLeftSection from '../commonLeftSection'

const CommonButtonAnswers = ({
  pageNo,
  handleNextStep,
  getSelected,
  pageData,
  answerSetKey = 'page',
  metaSelection,
  hasCameraImage,
  modalData,
}) => {
  const { question, description, answers } = pageData[answerSetKey]
  return (
    <div className="questionnaire-inner">
      <div className="inner-left">
        <CommonLeftSection
          metaTitle={getHiddenTitle(pageNo, metaSelection)}
          title={question}
          hasImage={hasCameraImage}
          introduction={description}
          modalData={modalData}
          answerSetKey={answerSetKey}
        />
      </div>
      <ul className="inner-right">
        {answers.map((answer, index) => (
          <li key={answer.title}>
            <CustomButton
              selected={getSelected(index)}
              title={answer.title}
              handleClick={() => handleNextStep(index, answer.title)}
            />
          </li>
        ))}
      </ul>
      <hr />
      <MoreInfoButton phone modalData={modalData} answerSetKey={answerSetKey} />
    </div>
  )
}
export default CommonButtonAnswers
