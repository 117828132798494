import axios from 'axios'
import { getCalculationPayload, sessionPutCall } from '../../utils/apiActions'
import { APP_URL, CALC_URL, MONTHLY_LOAN } from '../../utils/constants'
import { getObjValuesSum, isEmptyObj } from '../../utils/helpers'
import {
  getArea,
  getBuildingType,
  getFlatRoofIsolation,
  getFlorType,
  getHeating,
  getHeatingWarmWater,
  getIsolationBasement,
  getIsolationGround,
  getRoofType,
  getSlopingRoofIsolation,
  getTypeOfHeating,
  getVentilation,
  getWallIsolation,
  getWindowType,
} from '../../utils/resultHelpers'

export const SET_INVESTEER = 'SET_INVESTEER'
export const SET_ENERGIELENING = 'SET_ENERGIELENING'
export const SET_PREMIES = 'SET_PREMIES'
export const SET_MINDER_ENERGIE = 'SET_MINDER_ENERGIE'
export const SET_ELECTRICITY = 'SET_ELECTRICITY'
export const SET_GAS = 'SET_GAS'
export const SET_VERKOOP_ELEC = 'SET_VERKOOP_ELEC'
export const SET_CO2 = 'SET_CO2'
export const SET_BESPAAR_CO2 = 'SET_BESPAAR_CO2'
export const SET_BESPAAR = 'SET_BESPAAR'
export const SET_HEAT_LOSS_DATA = 'SET_HEAT_LOSS_DATA'
export const SET_AREA_DATA = 'SET_AREA_DATA'
export const SET_ACTUAL_EPC_LABEL = 'SET_ACTUAL_EPC_LABEL'

async function getLoan(value) {
  try {
    // const paramsResponse = await axios.get(`${APP_URL}/parameters`)
    const paramsResponse = JSON.parse(localStorage.getItem('parameters'))

    const parameters = paramsResponse?.data?.data?.[0] || []
    const effectiveAnualIntrest =
      parameters.find((param) => param?.name === 'Jaarlijkse rentevoet lening')
        ?.value || 0
    const jearsForLoan =
      parameters.find((param) => param?.name === 'Looptijd lening')?.value || 0

    // Naming convention according to ticket number #119
    if(effectiveAnualIntrest > 0){
    const X = effectiveAnualIntrest / 100 
    const Y = X / 12 
    const Z = jearsForLoan * 12
    const A = 1 + Y
    const B = A ** -Z
    const C = 1 - B
    const D = Y / C 
    return D * value
    }else{
      const Z = jearsForLoan * 12
      return value / Z
    }

  } catch {
    return 0
  }
}

export function setInvesteer(keyValue) {
  return async (dispatch, getState) => {
    const {
      result: { investeer },
    } = getState()
    const investeerPayload = { ...investeer, ...keyValue }
    const investeerVal = getObjValuesSum(investeerPayload)
    // console.log("investeer",investeer)
    // console.log("keyValue",keyValue)
    // console.log("investeerPayload",investeerPayload)
    let investeerValLoan = investeerVal - (investeerPayload?.groendak ? investeerPayload?.groendak : 0) - (investeerPayload?.klimaat? investeerPayload?.klimaat:0) - (investeerPayload?.verharding ? investeerPayload?.verharding: 0) - (investeerPayload?.regenwaterput ? investeerPayload?.regenwaterput : 0)

    const maximumbedragEnergielening = localStorage.getItem(
      'maximumbedragEnergielening',
    )
    localStorage.setItem('investeerValLoan',investeerValLoan); 
    // console.log("investeerValLoan",investeerValLoan)
    let loan = investeerValLoan
    if (investeerValLoan > maximumbedragEnergielening) {
      loan = await getLoan(maximumbedragEnergielening)
    } else {
      loan = await getLoan(investeerValLoan)
    }

    const energyLoan = loan.toFixed(2)
    dispatch({
      type: SET_INVESTEER,
      payload: investeerPayload,
    })
    dispatch({
      type: SET_ENERGIELENING,
      payload: energyLoan,
    })
  }
}

export function setEnergielening(energielening) {
  return {
    type: SET_ENERGIELENING,
    payload: energielening,
  }
}

export function setPremies(keyValue) {
  return (dispatch, getState) => {
    const {
      result: { premies },
    } = getState()

    dispatch({
      type: SET_PREMIES,
      payload: { ...premies, ...keyValue },
    })
  }
}

export function setMinderEnergie(keyValue) {
  return (dispatch, getState) => {
    const {
      result: { minderEnergie },
    } = getState()

    dispatch({
      type: SET_MINDER_ENERGIE,
      payload: { ...minderEnergie, ...keyValue },
    })
  }
}
export function setVerkoopElectricity(keyValue) {
  return (dispatch, getState) => {
    const {
      result: { verkoopElectricity },
    } = getState()

    dispatch({
      type: SET_VERKOOP_ELEC,
      payload: { ...verkoopElectricity, ...keyValue },
    })
  }
}

export function setElectricity(keyValue) {
  return (dispatch, getState) => {
    const {
      result: { electricity },
    } = getState()

    dispatch({
      type: SET_ELECTRICITY,
      payload: { ...electricity, ...keyValue },
    })
  }
}
export function setGas(keyValue) {
  return (dispatch, getState) => {
    const {
      result: { gas },
    } = getState()

    dispatch({
      type: SET_GAS,
      payload: { ...gas, ...keyValue },
    })
  }
}

export function setCO2(keyValue) {
  return (dispatch, getState) => {
    const {
      result: { co2 },
    } = getState()

    dispatch({
      type: SET_CO2,
      payload: { ...co2, ...keyValue },
    })
  }
}
export function setBespaar(keyValue) {
  return (dispatch, getState) => {
    const {
      result: { bespaar },
    } = getState()

    dispatch({
      type: SET_BESPAAR,
      payload: { ...bespaar, ...keyValue },
    })
  }
}

export function setArea(keyValue) {
  return (dispatch, getState) => {
    const {
      result: { area },
    } = getState()

    dispatch({
      type: SET_AREA_DATA,
      payload: { ...area, ...keyValue },
    })
  }
}

export function setBespaarCo2(bespaarCo2) {
  return {
    type: SET_BESPAAR_CO2,
    payload: bespaarCo2,
  }
}

export async function sendConsumptions(userId, pages, improvedPages) {
  if (!isEmptyObj(pages) && !isEmptyObj(improvedPages)) {
    const currentPayload = await getCalculationPayload(pages)
    const improvedPayload = await getCalculationPayload(improvedPages, true)
    const storedCurrentPayload = localStorage.getItem('currentPayload')
    const storeImprovedPayload = localStorage.getItem('improvedPayload')
    const storedResult = localStorage.getItem('result')

    const checkCurrentPayload =
      JSON.stringify(currentPayload) === storedCurrentPayload
    const checkImprovedPayload =
      JSON.stringify(improvedPayload) === storeImprovedPayload

    if (checkCurrentPayload && checkImprovedPayload) {
      const result = JSON.parse(storedResult)
      if (result) {
        const { current } = result.data
        const { newMeasures } = result.data
        const currentConsumption = current.energyUseGas + current.energyUseElec
        const futureConsumption =
          newMeasures.adjustedScaledGas + newMeasures.adjustedScaledElec

        sessionPutCall(userId, {
          current_consumption: currentConsumption,
          future_consumption: futureConsumption,
        })
      }
    }
  }
}

export async function getConsumptions(
  pages,
  improvedPages,
  setState,
  userId,
  state,
) {}

function getAnswer6And7(page7) {
  if (page7.answerNo > 13) {
    return {
      page6: {
        verwarming: 'Een andere bron',
        answerNo: 3,
      },
      page7: {
        ...page7,
        answerNo: page7.answerNo - 14,
      },
    }
  }
  if (page7.answerNo > 9) {
    return {
      page6: {
        verwarming: 'Stookolie',
        answerNo: 2,
      },
      page7: {
        ...page7,
        answerNo: page7.answerNo - 10,
      },
    }
  }
  if (page7.answerNo > 3) {
    return {
      page6: {
        verwarming: 'Elektriciteit',
        answerNo: 1,
      },
      page7: {
        ...page7,
        answerNo: page7.answerNo - 4,
      },
    }
  }
  return {
    page6: {
      verwarming: 'Gas',
      answerNo: 0,
    },
    page7: {
      ...page7,
      answerNo: page7.answerNo,
    },
  }
}

export function getPage6And7(pages) {
  const { 6: page6, 7: page7 } = pages

  if (!page7?.fromImprovement) {
    return {
      page6,
      page7,
    }
  }

  return getAnswer6And7(page7)
}

export function setHeatLossData(
  userId,
  pages = [],
  buildingData = [],
  isFirstTime,
) {
  return (dispatch) => {
    const buildingType = getBuildingType(pages?.[1] && pages?.[1].answerNo)
    const area = getArea(
      pages?.[3] && pages?.[3].answerNo,
      pages?.[1] && pages?.[1].answerNo,
      pages?.[3] && pages?.[3].oppervlakte_input,
      buildingData && buildingData[0],
    )
    const { page6, page7 } = getPage6And7(pages)
    const heating = getHeating(page6 && page6.answerNo, page7 && page7.answerNo)
    const heatingWarmWater = getHeatingWarmWater(
      pages?.[11] && pages?.[11].answerNo,
      page6 && page6.answerNo,
      page7 && page7.answerNo,
    )
    const typeOfHeating = getTypeOfHeating(
      page6 && page6.answerNo,
      page7 && page7.answerNo,
    )

    const ventilation = getVentilation(pages?.[21] && pages?.[21].answerNo)
    const slopingRoofIsolation = getSlopingRoofIsolation(
      pages?.[12] && pages?.[12].answerNo,
    )
    const flatRoofIsolation = getFlatRoofIsolation(
      pages?.[13] && pages?.[13].answerNo,
      slopingRoofIsolation,
    )
    const roofType = getRoofType(pages?.[13] && pages?.[13].answerNo)
    const florType = getFlorType(pages?.[20] && pages?.[20].answerNo)

    const windowType = getWindowType(
      (pages?.[15] && pages?.[15].answerNo) ||
        (pages?.[16] && pages?.[16].answerNo),
    )

    const isolationGround = getIsolationGround(
      pages?.[20] && pages?.[20].answerNo,
    )
    const isolationBasement = getIsolationBasement(
      pages?.[20] && pages?.[20].answerNo,
    )

    const wallIsolationAchter = getWallIsolation(
      pages?.[18] && pages?.[18].answerNo,
    )
    const wallIsolationVoor = getWallIsolation(
      pages?.[17] && pages?.[17].answerNo,
    )
    const wallIsolation =
      wallIsolationAchter === 'matig' || wallIsolationVoor === 'matig'
        ? 'matig'
        : wallIsolationAchter === 'nietZonderSpouw' ||
          wallIsolationVoor === 'nietZonderSpouw'
        ? 'nietZonderSpouw'
        : 'licht'

    const request = {
      bouwjaarVolgensEPC: 2015,
      pvAanwezig: pages?.[22] && pages?.[22].answerNo === 0,
      typeDak: roofType,
      typeGeometrie: area,
      typeGevelIsolatie: wallIsolation,
      typeHellendDakIsolatie: slopingRoofIsolation,
      typePlatDakIsolatie: flatRoofIsolation,
      typeVenster: windowType,
      typeVentilatie: ventilation,
      typeVerwarming: typeOfHeating,
      typeVloer: florType,
      typeVloerBovenKelderIsolatie: isolationBasement,
      typeVloerOpVolleGrondIsolatie: isolationGround,
      typeWoning: buildingType,
      verwarmSanitairWarmWaterMet: heatingWarmWater,
      verwarmingEnergiedrager: heating,
      zonneboilerAanwezig: pages?.[11] && pages?.[11].answerNo === 1,
    }

    fetch(
      `https://energie-dev.api.vlaanderen.be/energie-api/v1/berekenIndicatief`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-apikey': 'gkRYrwOouhFgYzhmt9Ov5JBGkZRqkZhJ',
        },
        body: JSON.stringify(request), // body data type must match "Content-Type" header
      },
    )
      .then((response) => response.json())
      .then((data) => {
        dispatch({ type: SET_HEAT_LOSS_DATA, payload: data })
        if (isFirstTime) {
          dispatch({ type: SET_ACTUAL_EPC_LABEL, payload: data.label })
        }
        sessionPutCall(userId, {
          epc_score: data.primairEnergieverbruikPerM2,
        })
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }
}

export function getQuantity(response) {
  return {
    gas: response?.data?.current?.energyUseGas,
    electricity: response?.data?.current?.energyUseElec,
  }
}
