import React, { useEffect, useRef } from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { setPageTitle } from '../../utils/helpers'
import { pageTitles } from '../../utils/constants'
import AdviceCard from '../../components/adviceCard'
import './getAdvice.scss'

const GetAdvice = ({ adviceLabelData, footerLabelData }) => {
  setPageTitle(pageTitles.home)
  const h1Ref = useRef(null)

  const { adviceCard, title, introduction, description } = adviceLabelData

  useEffect(() => {
    if (h1Ref?.current) {
      h1Ref.current.focus()
    }
  }, [])

  return (
    <>
      <a className="skiplink" href="#advice">
        Skip to content
      </a>
      <div className="container advice-container">
        <Header />
        <main id="advice" className="detail-layout content-container">
          <div className="advice-content">
            <h1
              tabIndex="-1"
              ref={h1Ref}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div
              className="advice-intro"
              dangerouslySetInnerHTML={{ __html: introduction }}
            />
            <div
              className="advice-description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          <AdviceCard
            adviceCard={adviceCard}
            dynamicClass="appartment-advice-card"
          />
        </main>
      </div>
      <Footer footer={footerLabelData} />
    </>
  )
}
export default GetAdvice
