import {
  SET_DEFAULT_STATE,
  SET_LOADING,
  SET_PAGE,
  SET_STATE_FROM_COOKIES,
} from '../actions/questionnaireActions'

const initialState = {
  pages: {
    1: {},
    2: {},
    3: { oppervlakte_input: 0 },
    4: {},
    5: {},
    6: {},
    7: {},
    8: {},
    9: {},
    23: { aantal_zonnepanelen: '' },
    26: {
      aardgasverbruik: 0,
      elektriciteitsverbruik: 0,
      zonnepanelen: 0,
      energieproductie: 0,
    },
  },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case SET_STATE_FROM_COOKIES:
      return action.payload
    case SET_PAGE:
      return {
        ...state,
        pages: action.payload,
      }
    case SET_DEFAULT_STATE:
      return {
        ...state,
        pages: action.payload,
      }
    default:
      return state
  }
}
