import React from 'react'

import { classNames } from '../../utils/helpers'

function getCardDescription(status, title) {
  switch (status) {
    case 'green':
    case 'midgreen':
      return 'Dit is al in orde! Een verdere investering weegt momenteel niet op tegen de voordelen.'
    case 'orange':
      return `Ontdek ons advies voor het verbeteren van ${title}.`
    default:
      return `Een verbetering dringt zich op. Ontdek nu ons advies voor het aanpakken van ${title}.`
  }
}

export default function Card(props) {
  const { status = '', title = '', subtitle = '', image } = props
  const statusClasses = classNames({
    status: true,
    green: status === 'green' || status === 'midgreen',
    orange: status === 'orange',
    red: status === 'red',
  })

  return (
    <article className="card">
      <div className="title">
        <h3>{title}</h3>
        <p>
          <span className={statusClasses} />
          <span className="sub-title">{subtitle}</span>
        </p>
      </div>
      <figure>{image}</figure>
      <p className="description">
        {getCardDescription(status, title?.toLowerCase())}
      </p>
    </article>
  )
}
