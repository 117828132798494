import React, { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import FocusTrap from 'focus-trap-react'

import './modal.scss'
import './solarMapModal.scss'

const ModalMeerInfo = (props) => {
  const {
    show,
    handleHideModal,
    modalTitle,
    modalContent,
    modalSubTitle,
    modalSubContent,
    modalLinkText,
    modalLinkURL,
    modalBtnText,
  } = props
  const modalRef = useRef(null)

  useEffect(() => {
    if (show) {
      modalRef.current.focus()
      document.getElementById('root').setAttribute('aria-hidden', false)
    } else {
      modalRef.current.blur()
      document.getElementById('root').setAttribute('aria-hidden', true)
    }
  }, [show])

  const children = (
    <div
      ref={modalRef}
      id="modal-meerInfo"
      className={show ? 'modal visible' : 'modal'}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-meerInfo-title"
      tabIndex="-1"
    >
      <div className="modal-inner">
        <div className="modal-header">
          <button
            type="button"
            className="button close icon-cross modal-close"
            data-target="modal-meerInfo"
            onClick={handleHideModal}
          >
            <span>Sluiten</span>
          </button>
        </div>
        <div className="modal-content">
          <h3 id="modal-meerInfo-title" dangerouslySetInnerHTML={{__html: modalTitle}}/>
          <p dangerouslySetInnerHTML={{__html: modalContent}}/>
          {modalSubTitle && <h4 dangerouslySetInnerHTML={{__html: modalSubTitle}} />}
          {modalSubContent && <p dangerouslySetInnerHTML={{__html: modalSubContent}} />}
          {modalLinkText && (
            <a
              href={modalLinkURL}
              alt="more-info-link-button"
              className="standalone-link"
              dangerouslySetInnerHTML={{__html: modalLinkText}}
            />
          )}
        </div>
        <div className="modal-actions">
          <button
            type="button"
            className="button button-primary modal-close"
            data-target="modal-meerInfo"
            onClick={handleHideModal}
            dangerouslySetInnerHTML={{__html: modalBtnText}}
          />
        </div>
      </div>
      <div
        className="modal-overlay modal-close"
        data-target="modal-meerInfo"
        tabIndex="-1"
        onMouseDown={handleHideModal}
        role="button"
        aria-label="modal"
      />
    </div>
  )

  return createPortal(
    show ? <FocusTrap>{children}</FocusTrap> : children,
    document.getElementsByTagName('body')[0],
  )
}
export default ModalMeerInfo
