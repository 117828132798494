import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const CookiesContext = createContext();

export const CookiesProvider = ({ children }) => {
  const [cookies, setCookies] = useState(Cookies.get());

  const updateCookie = (name, value, options) => {
    Cookies.set(name, value, options);
    setCookies(Cookies.get());
  };

  const removeCookie = (name, options) => {
    Cookies.remove(name, options);
    setCookies(Cookies.get());
  };

  // Use useEffect to check for cookie changes periodically (if needed)
  useEffect(() => {
    const interval = setInterval(() => {
      setCookies(Cookies.get());
    }, 1000); // Check every second (adjust the interval as needed)

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <CookiesContext.Provider value={{ cookies, updateCookie, removeCookie }}>
      {children}
    </CookiesContext.Provider>
  );
};

export const useCookies = () => {
  const context = useContext(CookiesContext);
  if (!context) {
    throw new Error('useCookies must be used within a CookiesProvider');
  }
  return context;
};
