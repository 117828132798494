import React, { useEffect, useRef, useState } from 'react'

import './styles.scss'

const CircularProgress = (props) => {
  const [offset, setOffset] = useState(0)
  const circleRef = useRef(null)
  const { size, progress, strokeWidth } = props

  const center = size / 2
  const radius = size / 2 - strokeWidth / 2
  const circumference = 2 * Math.PI * radius

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference
    setOffset(progressOffset)
    circleRef.current.style = 'transition: stroke-dashoffset 850ms ease-in-out;'
  }, [setOffset, circumference, progress, offset])

  return (
    <div className="progress">
      <p x={`${center}`} y={`${center}`} className="svg-circle-text">
        {progress}%
      </p>
      <svg className="svg" width={size} height={size}>
        <circle
          className="svg-circle-bg"
          stroke="rgba(0,157,224,0.1)"
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className="svg-circle"
          ref={circleRef}
          stroke="#009DE0"
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
      </svg>
    </div>
  )
}

export default CircularProgress
