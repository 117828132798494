import React, { useMemo,useState } from 'react'
import {
  BrowserRouter as Router,
  useParams
} from "react-router-dom";

import { pdfData } from '../../utils/resultHelpers'

import '../pdfDom/pdf-dom.scss'

const PdfDisplayer = () => {

  const { id } = useParams();
  const [pdfDom, setPdfDom] = useState({})

  const data= useMemo(() => {
    return pdfData(id,setPdfDom)
  }, [])

  return (
    <div id="pdf-dom" className="container pdf-container-displayer">
     {pdfDom ? <div dangerouslySetInnerHTML={{ __html: pdfDom }}></div> : null}
    </div>
  )
}
export default PdfDisplayer
