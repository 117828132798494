import { Formik } from 'formik'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import CustomCard from '../../../components/customCard'
import MoreInfoButton from '../../../components/moreInfoButton'
import { Images, pageStaticData } from '../../../utils/constants'
import { getHiddenTitle } from '../../../utils/helpers'
import CommonLeftSection from '../commonLeftSection'

import './styles.scss'

function validateValue(value, min, max) {
  const number = Number.parseInt(value, 10)
  return !!(number < min || number > max || number === ',')
}

function validateValue1(value) {
  return !!(value === '')
}

function isEnabled(values, min, max) {
  if(values.oppervlakte_input !== 0){
    return Object.values(values).every((val) => !validateValue(val, min, max))
  }
}

function roundedValue(value) {
  return value !== '' ? Math.round(Number.parseInt(value, 10)) : ''
}

function isvalid(values, min, max) {
  return Object.values(values).every((val) => !validateValue1(val,min))
}


const CommonCardWithInputAnswers = ({
  pageNo,
  pageData,
  handleButtonNext,
  handleNextStep,
  getSelected,
  answerSetKey = 'page',
  min,
  max,
  image,
  modalData,
  leftSectionImage,
  progressValue,
  imageTitle,
}) => {
  const { question, description, answers, input } = pageData[answerSetKey]
  const pages = useSelector(({ questionnaire }) => questionnaire.pages)

  const [validation, setValidation] = useState(false)

  const onFocus = () => {
    setValidation(true)
  }

  const onBlur = () => {
    setValidation(false)
  }

  const isMobileView = window.innerWidth < 768

  return (
    <Formik initialValues={pages[pageNo]}>
      {({ values = {}, handleChange }) => (
        <div
          className="questionnaire-inner"
          button-hidden={Boolean(validation).toString()}
        >
          <div className="inner-left">
            <CommonLeftSection
              metaTitle={getHiddenTitle(pageNo)}
              title={question}
              introduction={description}
              modalData={modalData}
              answerSetKey={answerSetKey}
              progressValue={progressValue}
              imageTitle={imageTitle}
              leftSectionImage={leftSectionImage}
            />
          </div>
          <ul className="inner-right">
            {answers.map((answer, index) => (
              <li key={answer.title}>
                <CustomCard
                  description={answer.title}
                  handleClick={() => handleNextStep(index, answer.title)}
                  selected={getSelected(index)}
                  image={Images[image[index]]}
                />
              </li>
            ))}
            <div className="below-card-container">
              {input.map((item) => (
                <div key={item.key} className="form-columns">
                  <label htmlFor={item.key}>{item.title}</label>
                  <div
                    className="field-message description"
                    id={`${item.key}-description`}
                  >
                    {pageStaticData[pageNo].info}
                    <div className="accolade " />
                  </div>
                  <div className="form-item-column number-input-style">
                    <input
                      type="number"
                      id={item.key}
                      name={item.key}
                      className="number"
                      value={roundedValue(values[item.key])}
                      onChange={handleChange}
                      onFocus={onFocus}
                      onBlur={onBlur}
                    />
                    {validateValue(values[item.key], min, max) && (
                      <div className="form-item">
                        <div
                          className="field-message error"
                          role="alert"
                          id="aardgasverbruik-aardgasverbruik-message"
                        >
                          {pageStaticData[pageNo].errorMessage}
                          <div className="accolade " />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="form-item-column" />
                </div>
              ))}
              <br />
              <button
                onClick={() => handleButtonNext(values)}
                type="button"
                className="button button-primary button-large"
                disabled={!isEnabled(values, min, max) || !isvalid(values,min)}
               
              >
                Volgende
              </button>
            </div>
          </ul>
          {validation && isMobileView ? null : (
            <>
              <hr />
              <MoreInfoButton
                phone
                modalData={modalData}
                answerSetKey={answerSetKey}
              />
            </>
          )}
        </div>
      )}
    </Formik>
  )
}

export default CommonCardWithInputAnswers
