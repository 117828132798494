import axios from 'axios'
import { INPUT_KEYS, pageTitles, APP_URL } from './constants'
import { parseRate, getCalculationPayload } from './apiActions'
// import {getPage6And7,getHeating} from './resultHelpers'

export const classNames = (classesObj) => {
  const classes = Object.entries(classesObj).map(([key, value]) =>
    value ? key : undefined,
  )
  return classes.filter((cls) => Boolean(cls)).join(' ')
}

export const getButtonTitle = (page, pages) => {
  switch (page) {
    case 1:
      return 'Vorige'
    case 2:
      return 'Type woning'
    case 3:
      return 'Bouwjaar'
    case 4:
      return 'Oppervlakte'
    case 5:
      return 'Bewoner'
    case 6:
      return 'Bewoners'
    case 7:
      return 'Verwarming'
    case 8:
      switch (pages[page - 2]?.answerNo) {
        case 0:
          return 'Gas'
        case 1:
          return 'Elektriciteit'
        case 2:
          return 'Stookolie'
        case 3:
          return 'Een andere bron'
        default:
          return ''
      }
    case 9:
      switch (pages[page - 3]?.answerNo) {
        case 0:
          switch (pages[page - 2]?.answerNo) {
            case 0:
              return 'Gas'
            default:
              return 'Verwarmingselementen'
          }
        case 1:
          switch (pages[page - 2]?.answerNo) {
            case 0:
              return 'Elektriciteit'
            default:
              return 'Verwarmingselementen'
          }
        case 2:
          switch (pages[page - 2]?.answerNo) {
            case 0:
              return 'Stookolie'
            default:
              return 'Verwarmingselementen'
          }
        default:
          switch (pages[page - 2]?.answerNo) {
            case 0:
              return 'Verwarmingselementen'
            default:
              return 'Een andere bron'
          }
      }
    // return 'Verwarmingselementen'
    case 10:
      return 'Verwarming leefruimtes'
    case 11:
      return 'Verwarming slaapkamers'
    case 12:
      return 'Warm water'
    case 13:
      return 'Hoofddak'
    case 15:
      return 'Bijdak'
    // case 15:
    //   return 'Verwarmde ruimtes'
    case 16:
      return 'Ramen leefruimte'
    case 17:
      return 'Ramen slaapkamers'
    case 18:
      return 'Voorgevel'
    case 19:
      return 'Achtergevel'
    case 20:
      switch (pages[1]?.answerNo) {
        case 0:
        case 1:
          return 'Zijgevels'
        default:
          return 'Achtergevel'
      }
    case 21:
      return 'Vloerisolatie'
    case 22:
      return 'Ventilatie'
    case 23:
      return 'Zonnepanelen'
    case 24:
      return 'Aantal zonnepanelen'
    case 25:
      // switch (pages[page - 3]?.answerNo) {
      //   case 0:
      //     return 'Terugdraaiende teller'
      //   case 1:
      //     return 'Zonnepanelen'
      //   default:
      //     return ''
      // }
      return 'Aantal zonnepanelen'
    case 26:
      return 'Digitale meter'
    case 27:
      return 'Energieverbruik en -productie'
    case 28:
      return 'Groendak'
    case 29:
      return 'Voor- of achtertuin'
    case 30:
      return 'Regenwaterput'
    case 31:
      switch (pages[page - 3]?.answerNo) {
        case 0:
          return 'Voor- of achtertuin'
        default:
          return 'Verharding'
      }
    default:
      return 'Bewoners'
  }
}

export const setPageTitle = (title) => {
  document.title = title || ''
}

export const getHiddenTitle = (pageNo, selectionNo) => {
  const title = pageTitles[`questionnaire${pageNo}`]

  if (title) {
    return title.replace(' | Gent: Check je huis', '')
  }

  if (pageNo === 5) {
    switch (selectionNo) {
      case 0:
        return 'Gas'
      case 1:
        return 'Elektriciteit'
      case 2:
        return 'Stookolie'
      case 3:
        return 'Een andere bron'
      default:
        return ''
    }
  }
}

export const getQuestionKey = (pageNo, page6AnswerNo) => {
  if (Number(pageNo) === 7) {
    switch (page6AnswerNo) {
      case 0:
        return 'gas'
      case 1:
        return 'elektriciteit'
      case 2:
        return 'stookolie'
      case 3:
        return 'ean_andere_bron'
      default:
        return 'gas'
    }
  }

  return getHiddenTitle(pageNo) && getHiddenTitle(pageNo).toLowerCase()
    ? getHiddenTitle(pageNo)?.toLowerCase()?.split(' ')?.join('_')
    : null
}

export const getSelectedIndexPage11 = (pageSixAnswer) => {
  switch (pageSixAnswer) {
    case 0:
    case 2:
      return 0
    case 1:
      return 3
    case 3:
      return 4
    default:
      return undefined
  }
}

export const getSelectedIndexPage12 = (pageTwoAnswer) => {
  switch (pageTwoAnswer) {
    case 0:
    case 1:
    case 2:
      return 0
    case 3:
      return 1
    case 4:
      return 2
    default:
      return undefined
  }
}

export function getCookieMeta() {
  return {
    path: '/',
    expires: new Date(Date.now() + 86400000),
  }
}

export function parseHomeJson(obj) {
  return {
    heading: obj.heading,
    introduction: obj.introduction,
    adres: obj.adres,
    gemeente: obj.gemeente,
    straat: obj.straat,
    nummer: obj.nummer,
    bus: obj.bus,
    optioneel: obj.optioneel,
    privacyText: obj.privacy_text,
    buttonText: obj.button_text,
    linkText: obj.link_text,
    homePageIcon: obj.home_page_icon,
    privacyLink: obj.privacy_link,
    privacyTarget: obj.privacy_target,
  }
}

export function parsePageNotFound(obj) {
  return {
    title: obj.title,
    introduction: obj.introduction,
    linkText: obj.link_text,
  }
}

export function parseAdvice(obj) {
  return {
    title: obj.title,
    introduction: obj.introduction,
    description: obj.description,
    adviceCard: {
      title: obj.advice_card_title,
      introduction: obj.advice_card_introduction,
      buttonText: obj.advice_card_button_text,
      buttonLink: obj.advice_card_button_link,
      linkText: obj.advice_card_link_text,
      linkLink: obj.advice_card_link_link,
    },
  }
}
function separateByPattern(obj, filter) {
  let key
  const keys = {}
  for (key in obj)
    if (obj.hasOwnProperty(key) && filter.test(key)) keys[key] = obj[key]
  return keys
}

// example:
export function parserResult(obj) {
  return {
    infoCard: obj.info_card,
    /* multiCard: {
      title: obj.multi_card_title,
      subTitle: {
        key: obj.multi_card_sub_title_key,
        value: obj.multi_card_sub_title_value,
      },
      infoText: {
        key: obj.multi_card_info_text_key,
        value: obj.multi_card_info_text_value,
      },
      leftButtonText: obj.multi_card_left_button_text,
      rightButtonText: obj.multi_card_right_button_text,
      invest: {
        key: obj.multi_card_invest_key,
        value: obj.multi_card_invest_value,
      },
      use: {
        key: obj.multi_card_use_key,
        value: obj.multi_card_use_value,
      },
      save: {
        key: obj.multi_card_save_key,
        value: obj.multi_card_save_value,
      },
      plusButtonDefaultText: obj.multi_card_plus_button_default_text,
      plusButtonSelectedText: obj.multi_card_plus_button_selected_text,
    }, */
    singleCard: {
      title: obj.single_card_title,
      description: obj.single_card_description,
      leftButtonText: obj.single_card_left_button_text,
      rightButtonText: obj.single_card_right_button_text,
    },
    emailCard: {
      title: obj.email_card_title,
      emailLabel: obj.email_card_email_label,
      checkBoxText: obj.email_card_checkbox_text,
      privacyText: obj.email_card_privacy_text,
      buttonText: obj.email_card_button_text,
      success_text: obj.email_success_text,
      error_text: obj.email_error_text,
    },
    adviceCard: {
      title: obj.advice_card_title,
      introduction: obj.advice_card_introduction,
      buttonText: obj.advice_card_button_text,
      buttonLink: obj.advice_card_button_link,
      linkText: obj.advice_card_link_text,
      linkLink: obj.advice_card_link_link,
    },
    improvementCard: separateByPattern(obj, /improvement/),
    savingCard: separateByPattern(obj, /saving/),
    improvementModal: separateByPattern(obj, /improvement_modal/),
    epcCard: separateByPattern(obj, /epc/),
    restartCard: separateByPattern(obj, /restart/),
    disclaimerCard: separateByPattern(obj, /disclaimer/),
  }
}

function getRequiredKeys(subStr, keyValueObj) {
  return Object.keys(keyValueObj).filter((key) => key.includes(subStr))
}

export function parseQuestionnaire(keyValueObj) {
  const arr = [...Array(31).keys()]

  return arr.reduce((prevValue, currentValue) => {
    const pageNo = currentValue + 1
    const answersKeys = getRequiredKeys(`page_${pageNo}_answer_`, keyValueObj)
    const inputKeys = getRequiredKeys(`page_${pageNo}_input_`, keyValueObj)

    const answerKeysForGas = getRequiredKeys(`page_7_gas_answer_`, keyValueObj)
    const answerKeysForElektriciteit = getRequiredKeys(
      `page_7_elektriciteit_answer_`,
      keyValueObj,
    )
    const answerKeysForStookolie = getRequiredKeys(
      `page_7_stookolie_answer_`,
      keyValueObj,
    )
    const answerKeysForEenAndereBron = getRequiredKeys(
      `page_7_een_andere_bron_answer_`,
      keyValueObj,
    )

    const page =
      pageNo !== 7
        ? {
            page: {
              question: keyValueObj[`page_${pageNo}_question`],
              description: keyValueObj[`page_${pageNo}_description`],
              answers: answersKeys.map((ansKey) => ({
                title: keyValueObj[ansKey],
              })),
              input: inputKeys.map((inputKey, index) => ({
                title: keyValueObj[inputKey],
                key: INPUT_KEYS[`page_${pageNo}_input_${index + 1}_title`],
              })),
            },
          }
        : {
            gas: {
              question: keyValueObj.page_7_gas_question,
              description: keyValueObj.page_7_gas_description,
              answers: answerKeysForGas.map((ansKey) => ({
                title: keyValueObj[ansKey],
              })),
              input: [],
            },
            elektriciteit: {
              question: keyValueObj.page_7_elektriciteit_question,
              description: keyValueObj.page_7_elektriciteit_description,
              answers: answerKeysForElektriciteit.map((ansKey) => ({
                title: keyValueObj[ansKey],
              })),
              input: [],
            },
            stookolie: {
              question: keyValueObj.page_7_stookolie_question,
              description: keyValueObj.page_7_stookolie_description,
              answers: answerKeysForStookolie.map((ansKey) => ({
                title: keyValueObj[ansKey],
              })),
              input: [],
            },
            'Een andere bron': {
              question: keyValueObj.page_7_een_andere_bron_question,
              description: keyValueObj.page_7_een_andere_bron_description,
              answers: answerKeysForEenAndereBron.map((ansKey) => ({
                title: keyValueObj[ansKey],
              })),
              input: [],
            },
          }
    return {
      ...prevValue,
      [pageNo]: {
        id: pageNo,
        ...page,
      },
    }
  }, {})
}

export function parseMeerInfoModal(keyValueObj) {
  const arr = [...Array(31).keys()]

  return arr.reduce((prevValue, currentValue) => {
    const pageNo = currentValue + 1
    const modalItems = getRequiredKeys(`page_${pageNo}_modal_`, keyValueObj)

    const modalItemsForGas = getRequiredKeys(`page_7_gas_modal_`, keyValueObj)
    const modalItemsForElektriciteit = getRequiredKeys(
      `page_7_elektriciteit_modal_`,
      keyValueObj,
    )
    const modalItemsForStookolie = getRequiredKeys(
      `page_7_stookolie_modal_`,
      keyValueObj,
    )
    const modalItemsForEenAndereBron = getRequiredKeys(
      `page_7_een_andere_bron_modal_`,
      keyValueObj,
    )

    const page =
      pageNo !== 7
        ? {
            page: {
              title: keyValueObj[modalItems[0]],
              content: keyValueObj[modalItems[1]],
              subtitle: keyValueObj[modalItems[2]],
              subcontent: keyValueObj[modalItems[3]],
              linkText: keyValueObj[modalItems[4]],
              linkUrl: keyValueObj[modalItems[5]],
              buttonText: keyValueObj[modalItems[6]],
            },
          }
        : {
            gas: {
              title: keyValueObj[modalItemsForGas[0]],
              content: keyValueObj[modalItemsForGas[1]],
              subtitle: keyValueObj[modalItemsForGas[2]],
              subcontent: keyValueObj[modalItemsForGas[3]],
              linkText: keyValueObj[modalItemsForGas[4]],
              linkUrl: keyValueObj[modalItemsForGas[5]],
              buttonText: keyValueObj[modalItemsForGas[6]],
            },
            elektriciteit: {
              title: keyValueObj[modalItemsForElektriciteit[0]],
              content: keyValueObj[modalItemsForElektriciteit[1]],
              subtitle: keyValueObj[modalItemsForElektriciteit[2]],
              subcontent: keyValueObj[modalItemsForElektriciteit[3]],
              linkText: keyValueObj[modalItemsForElektriciteit[4]],
              linkUrl: keyValueObj[modalItemsForElektriciteit[5]],
              buttonText: keyValueObj[modalItemsForElektriciteit[6]],
            },
            stookolie: {
              title: keyValueObj[modalItemsForStookolie[0]],
              content: keyValueObj[modalItemsForStookolie[1]],
              subtitle: keyValueObj[modalItemsForStookolie[2]],
              subcontent: keyValueObj[modalItemsForStookolie[3]],
              linkText: keyValueObj[modalItemsForStookolie[4]],
              linkUrl: keyValueObj[modalItemsForStookolie[5]],
              buttonText: keyValueObj[modalItemsForStookolie[6]],
            },
            'Een andere bron': {
              title: keyValueObj[modalItemsForEenAndereBron[0]],
              content: keyValueObj[modalItemsForEenAndereBron[1]],
              subtitle: keyValueObj[modalItemsForEenAndereBron[2]],
              subcontent: keyValueObj[modalItemsForEenAndereBron[3]],
              linkText: keyValueObj[modalItemsForEenAndereBron[4]],
              linkUrl: keyValueObj[modalItemsForEenAndereBron[5]],
              buttonText: keyValueObj[modalItemsForEenAndereBron[6]],
            },
          }
    return {
      ...prevValue,
      [pageNo]: {
        id: pageNo,
        ...page,
      },
    }
  }, {})
}
export async function setCardsOptionLog(
  title,
  options,
  logic,
  userId,
  consumptions,
) {
  if (consumptions > 0) {
    const data = []
    let card
    let timeout
    if (title === 'Hoofddak') {
      ;(card = 'card1'), (timeout = 5)
    }
    if (title === 'Bijdak') {
      ;(card = 'card2'), (timeout = 6)
    }
    if (title === 'Ramen leefruimte') {
      ;(card = 'card3'), (timeout = 7)
    }
    if (title === 'Ramen slaapkamers') {
      ;(card = 'card4'), (timeout = 8)
    }
    if (title === 'Voorgevel') {
      ;(card = 'card5'), (timeout = 7)
    }
    if (title === 'Achter- en zijgevels') {
      ;(card = 'card6'), (timeout = 8)
    }
    if (title === 'Vloerisolatie') {
      ;(card = 'card7'), (timeout = 9)
    }
    if (title === 'Verwarming') {
      ;(card = 'card8'), (timeout = 10)
    }
    if (title === 'Ventilatie') {
      ;(card = 'card9'), (timeout = 11)
    }
    if (title === 'Warm water') {
      ;(card = 'card10'), (timeout = 12)
    }
    if (title === 'Zonnepanelen') {
      ;(card = 'card11'), (timeout = 13)
    }
    if (title === 'Groendak') {
      ;(card = 'card12'), (timeout = 14)
    }
    if (title === 'Zonnewering') {
      ;(card = 'card13'), (timeout = 15)
    }
    if (title === 'Regenput') {
      ;(card = 'card14'), (timeout = 16)
    }
    if (title === 'Vergroenen') {
      ;(card = 'card15'), (timeout = 17)
    }

    const statusText = `Card's Option\r\n**************
  => Options : ${options}
  => Consumption : consumptions
  => Logic : ${logic}\r\n\r\n`

    data.push({
      logs: statusText,
      card_id: card,
      userId,
    })
    setTimeout(() => {
      axios.post(`${APP_URL}/logs/addCardsLogs`, { data })
    }, 1000 * timeout)
  }
}

export function getModalOptions(title, pages,pageIndex, userId) {
  const paramsResponse = JSON.parse(localStorage.getItem('parameters'))
  const parametersData = paramsResponse?.data?.data?.[0] || []
  const consumptionThreshold =
    parametersData.find(
      (param) => param?.name === 'Plafond verbruik voor warmtepomp',
    )?.value || 0

  const consumptions = JSON.parse(localStorage.getItem('energyGasNew'))
  if (title === 'Hoofddak') {
    if (pages?.[12]?.answerNo === 0) {
      setCardsOptionLog(
        'Hoofddak',
        `'{ 'Matig geïsoleerd': 2, 'Goed geïsoleerd': 3 }`,
        'pages?.[12]?.answerNo === 0',
        userId,
        consumptions,
      )
      return [
        // { 'Licht geïsoleerd': 1, 'Matig geïsoleerd': 2, 'Goed geïsoleerd': 3 },
        { 'Matig geïsoleerd': 2, 'Goed geïsoleerd': 3 },
      ]
    }
    if (pages?.[12]?.answerNo === 2) {
      setCardsOptionLog(
        'Hoofddak',
        `{ 'Goed geïsoleerd': 3 }`,
        'pages?.[12]?.answerNo === 0',
        userId,
        consumptions,
      )
      return [{ 'Goed geïsoleerd': 3 }]
    }
    setCardsOptionLog(
      'Hoofddak',
      `Matig geïsoleerd': 2, 'Goed geïsoleerd': 3`,
      'Default',
      userId,
      consumptions,
    )
    return [{ 'Matig geïsoleerd': 2, 'Goed geïsoleerd': 3 }]
  }

  if (title === 'Bijdak') {
    if (pages?.[13]?.answerNo === 2) {
      setCardsOptionLog(
        'Bijdak',
        `{ 'Goed geïsoleerd': 3 }`,
        'pages?.[13]?.answerNo === 2',
        userId,
        consumptions,
      )

      return [{ 'Goed geïsoleerd': 3 }]
    }
    setCardsOptionLog(
      'Bijdak',
      `'{ 'Matig geïsoleerd': 2, 'Goed geïsoleerd': 3 }`,
      'Default',
      userId,
      consumptions,
    )
    return [{ 'Matig geïsoleerd': 2, 'Goed geïsoleerd': 3 }]
  }

  if (title === 'Ramen leefruimte') {
    if (pages?.[15]?.answerNo === 0 || pages?.[15]?.answerNo === 1) {
      setCardsOptionLog(
        'Ramen leefruimte',
        `{ Hoogrendementsglas: 2, 'Driedubbel glas': 3 }`,
        'pages?.[15]?.answerNo === 0 || pages?.[15]?.answerNo === 1',
        userId,
        consumptions,
      )
      return [{ Hoogrendementsglas: 2, 'Driedubbel glas': 3 }]
    }
    if (pages?.[15]?.answerNo === 2) {
      setCardsOptionLog(
        'Ramen leefruimte',
        `{ 'Driedubbel glas': 3 }`,
        'pages?.[15]?.answerNo === 2',
        userId,
        consumptions,
      )
      return [{ 'Driedubbel glas': 3 }]
    }
    setCardsOptionLog(
      'Ramen leefruimte',
      `{ Hoogrendementsglas: 2, 'Driedubbel glas': 3 }`,
      'Default',
      userId,
      consumptions,
    )
    return [{ Hoogrendementsglas: 2, 'Driedubbel glas': 3 }]
  }

  if (title === 'Ramen slaapkamers') {
    if (pages?.[16]?.answerNo === 0 || pages?.[16]?.answerNo === 1) {
      setCardsOptionLog(
        'Ramen slaapkamers',
        `{ Hoogrendementsglas: 2, 'Driedubbel glas': 3 }`,
        'pages?.[16]?.answerNo === 0 || pages?.[16]?.answerNo === 1',
        userId,
        consumptions,
      )
      return [{ Hoogrendementsglas: 2, 'Driedubbel glas': 3 }]
    }
    if (pages?.[16]?.answerNo === 2) {
      setCardsOptionLog(
        'Ramen slaapkamers',
        `{ 'Driedubbel glas': 3 }`,
        'pages?.[16]?.answerNo === 2',
        userId,
        consumptions,
      )
      return [{ 'Driedubbel glas': 3 }]
    }
    setCardsOptionLog(
      'Ramen slaapkamers',
      `{ Hoogrendementsglas: 2, 'Driedubbel glas': 3 }`,
      'Default',
      userId,
      consumptions,
    )
    return [{ Hoogrendementsglas: 2, 'Driedubbel glas': 3 }]
  }

  if (title === 'Voorgevel') {
    if (pages?.[17]?.answerNo === 1 || pages?.[17]?.answerNo === 2) {
      setCardsOptionLog(
        'Voorgevel',
        `{ 'Wel geïsoleerd': 0 }`,
        'pages?.[17]?.answerNo === 1 || pages?.[17]?.answerNo === 2)',
        userId,
        consumptions,
      )
      return [{ 'Wel geïsoleerd': 0 }]
    }
    setCardsOptionLog(
      'Voorgevel',
      `{ 'Geïsoleerd in de spouw': 2, 'Wel geïsoleerd': 0 }`,
      'Default',
      userId,
      consumptions,
    )
    return [{ 'Geïsoleerd in de spouw': 2, 'Wel geïsoleerd': 0 }]
  }

  if (title === 'Achter- en zijgevels') {
    if (pages?.[18]?.answerNo === 1 || pages?.[18]?.answerNo === 2) {
      setCardsOptionLog(
        'Achter- en zijgevels',
        `{ 'Wel geïsoleerd': 0 }`,
        'pages?.[18]?.answerNo === 1 || pages?.[18]?.answerNo === 2',
        userId,
        consumptions,
      )
      return [{ 'Wel geïsoleerd': 0 }]
    }
    setCardsOptionLog(
      'Achter- en zijgevels',
      `{ 'Geïsoleerd in de spouw': 2, 'Wel geïsoleerd': 0 }`,
      'Default',
      userId,
      consumptions,
    )
    return [{ 'Geïsoleerd in de spouw': 2, 'Wel geïsoleerd': 0 }]
  }

  if (title === 'Vloerisolatie') {
    if (pages?.[20]?.answerNo === 1 || pages?.[20]?.answerNo === 2) {
      setCardsOptionLog(
        'Vloerisolatie',
        `{ 'Wel geïsoleerd': 0 }`,
        'pages?.[20]?.answerNo === 1 || pages?.[20]?.answerNo === 2',
        userId,
        consumptions,
      )
      return [{ 'Wel geïsoleerd': 0 }]
    }
    setCardsOptionLog(
      'Vloerisolatie',
      `{ 'Gedeeltelijk geïsoleerd': 2, 'Wel geïsoleerd': 0 }`,
      'Default',
      userId,
      consumptions,
    )
    return [{ 'Gedeeltelijk geïsoleerd': 2, 'Wel geïsoleerd': 0 }]
  }

  if (title === 'Verwarming') {
    if (pages?.[6]?.answerNo === 0) {
      if (pages?.[7]?.answerNo === 0 || pages?.[7]?.answerNo === 1) {
        // if (consumptions < consumptionThreshold) {
        //   if (pages?.[28]?.answerNo === 0) {
        //     setCardsOptionLog(
        //       'Verwarming',
        //       `{'Warmtepomp lucht/lucht': 6, 'Warmtepomp lucht/water': 7,}`,
        //       `pages?.[6]?.answerNo === 0 && pages?.[7]?.answerNo === 0 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
        //       userId,
        //       consumptions,
        //     )

        //     return [
        //       {
        //         // Condensatieketel: 2,
        //         // 'Hybride ketel': 3,
        //         'Warmtepomp lucht/lucht': 6,
        //         'Warmtepomp lucht/water': 7,
        //         // 'Warmtepomp water/water': 8,
        //         // Warmtenet: 14,
        //       },
        //     ]
        //   }
        //   setCardsOptionLog(
        //     'Verwarming',
        //     `{ 'Warmtepomp lucht/lucht': 6, 'Warmtepomp lucht/water': 7, 'Warmtepomp bodem/water': 9,}`,
        //     `pages?.[6]?.answerNo === 0 && pages?.[7]?.answerNo === 0 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
        //     userId,
        //     consumptions,
        //   )

        //   return [
        //     {
        //       // Condensatieketel: 2,
        //       // 'Hybride ketel': 3,
        //       'Warmtepomp lucht/lucht': 6,
        //       'Warmtepomp lucht/water': 7,
        //       // 'Warmtepomp water/water': 8,
        //       'Warmtepomp bodem/water': 9,
        //       // Warmtenet: 14,
        //     },
        //   ]
        // }
        if (pages?.[28]?.answerNo === 0) {
          setCardsOptionLog(
            'Verwarming',
            `{Kachels: 0}`,
            `pages?.[6]?.answerNo === 0 && pages?.[7]?.answerNo === 0 && consumptions > ${consumptionThreshold} &&pages?.[28]?.answerNo === 0`,
            userId,
            consumptions,
          )

          return [
            {
              // Kachels: 0,
              Condensatieketel: 2,
              // 'Hybride ketel': 3,
              // 'Warmtepomp bodem/water': 9,
              // 'Warmtepomp water/water': 8,
              'Warmtepomp lucht/water': 7,
              'Warmtepomp lucht/lucht': 6,
              'Hybride warmtepomp': 5,
              // Warmtenet: 14,
            },
          ]
        }
        setCardsOptionLog(
          'Verwarming',
          `{Kachels: 0}`,
          `pages?.[6]?.answerNo === 0 && pages?.[7]?.answerNo === 0 && consumptions > ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
          userId,
          consumptions,
        )

        return [
          {
            // Kachels: 0,
            Condensatieketel: 2,
            // 'Hybride ketel': 3,
            'Warmtepomp bodem/water': 9,
            // 'Warmtepomp water/water': 8,
            'Warmtepomp lucht/water': 7,
            'Warmtepomp lucht/lucht': 6,
            'Hybride warmtepomp': 5,
            // Warmtenet: 14,
          },
        ]
      }
      if (pages?.[7]?.answerNo === 2) {
        // if (consumptions < consumptionThreshold) {
        //   if (pages?.[28]?.answerNo === 0) {
        //     setCardsOptionLog(
        //       'Verwarming',
        //       `{'Warmtepomp lucht/water': 7}`,
        //       `pages?.[6]?.answerNo === 0 && (pages?.[7]?.answerNo === 1 || pages?.[7]?.answerNo === 2) && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
        //       userId,
        //       consumptions,
        //     )

        //     return [
        //       {
        //         // 'Warmtepomp water/water': 8,
        //         'Warmtepomp lucht/water': 7,
        //         // 'Warmtepomp lucht/lucht': 6,
        //         // Warmtenet: 14,
        //       },
        //     ]
        //   }
        //   setCardsOptionLog(
        //     'Verwarming',
        //     `{'Warmtepomp bodem/water': 9,'Warmtepomp lucht/water': 7}`,
        //     `pages?.[6]?.answerNo === 0 && (pages?.[7]?.answerNo === 1 || pages?.[7]?.answerNo === 2) && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
        //     userId,
        //     consumptions,
        //   )

        //   return [
        //     {
        //       // 'Warmtepomp water/water': 8,
        //       'Warmtepomp bodem/water': 9,
        //       'Warmtepomp lucht/water': 7,
        //       // 'Warmtepomp lucht/lucht': 6,
        //       // Warmtenet: 14,
        //     },
        //   ]
        // }
        if (pages?.[28]?.answerNo === 0) {
          setCardsOptionLog(
            'Verwarming',
            `{'Hybride ketel': 3}`,
            `pages?.[6]?.answerNo === 0 && (pages?.[7]?.answerNo === 1 || pages?.[7]?.answerNo === 2) && consumptions > ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
            userId,
            consumptions,
          )

          return [
            {
              'Hybride warmtepomp': 5,
              // 'Hybride ketel': 3,
              // 'Warmtepomp water/water': 8,
              // 'Warmtepomp bodem/water': 9,
              'Warmtepomp lucht/water': 7,
              'Warmtepomp lucht/lucht': 6,
              // Warmtenet: 14,
            },
          ]
        }
        setCardsOptionLog(
          'Verwarming',
          `{'Hybride ketel': 3}`,
          `pages?.[6]?.answerNo === 0 && (pages?.[7]?.answerNo === 1 || pages?.[7]?.answerNo === 2) && consumptions > ${consumptionThreshold} || pages?.[28]?.answerNo != 0)`,
          userId,
          consumptions,
        )

        return [
          {
            'Hybride warmtepomp': 5,
            // 'Hybride ketel': 3,
            // 'Warmtepomp water/water': 8,
            'Warmtepomp bodem/water': 9,
            'Warmtepomp lucht/water': 7,
            'Warmtepomp lucht/lucht': 6,
            // Warmtenet: 14,
          },
        ]
      }
      if (pages?.[7]?.answerNo === 3) {
        // if (consumptions < consumptionThreshold) {
        //   if (pages?.[28]?.answerNo === 0) {
        //     setCardsOptionLog(
        //       'Verwarming',
        //       `{'Warmtepomp lucht/water': 7}`,
        //       `pages?.[6]?.answerNo === 0 && pages?.[7]?.answerNo === 3 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
        //       userId,
        //       consumptions,
        //     )

        //     return [
        //       {
        //         // 'Warmtepomp water/water': 8,
        //         'Warmtepomp lucht/water': 7,
        //         // 'Warmtepomp lucht/lucht': 6,
        //         // Warmtenet: 14,
        //       },
        //     ]
        //   }
        //   setCardsOptionLog(
        //     'Verwarming',
        //     `{'Warmtepomp bodem/water': 9,'Warmtepomp lucht/water': 7}`,
        //     `pages?.[6]?.answerNo === 0 && pages?.[7]?.answerNo === 3 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
        //     userId,
        //     consumptions,
        //   )

        //   return [
        //     {
        //       // 'Warmtepomp water/water': 8,
        //       'Warmtepomp bodem/water': 9,
        //       'Warmtepomp lucht/water': 7,
        //       // 'Warmtepomp lucht/lucht': 6,
        //       // Warmtenet: 14,
        //     },
        //   ]
        // }
        if (pages?.[28]?.answerNo === 0) {
          setCardsOptionLog(
            'Verwarming',
            `{'Hybride warmtepomp': 5}`,
            `pages?.[6]?.answerNo === 0 && pages?.[7]?.answerNo === 3 && consumptions > ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
            userId,
            consumptions,
          )

          return [
            {
              // 'Hybride warmtepomp': 5,
              // 'Hybride ketel': 3,
              // 'Warmtepomp water/water': 8,
              // 'Warmtepomp bodem/water': 9,
              'Warmtepomp lucht/water': 7,
              'Warmtepomp lucht/lucht': 6,
              // Warmtenet: 14,
            },
          ]
        }
        setCardsOptionLog(
          'Verwarming',
          `{'Hybride warmtepomp': 5}`,
          `pages?.[6]?.answerNo === 0 && pages?.[7]?.answerNo === 3 && consumptions > ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
          userId,
          consumptions,
        )

        return [
          {
            // 'Hybride warmtepomp': 5,
            // 'Hybride ketel': 3,
            // 'Warmtepomp water/water': 8,
            'Warmtepomp bodem/water': 9,
            'Warmtepomp lucht/water': 7,
            'Warmtepomp lucht/lucht': 6,
            // Warmtenet: 14,
          },
        ]
      }
    }
    if (pages?.[6]?.answerNo === 1) {
      if (pages?.[7]?.answerNo === 0) {
        if (pages?.[28]?.answerNo === 0) {
          setCardsOptionLog(
            'Verwarming',
            `{'Warmtepomp lucht/lucht': 6,'Warmtepomp lucht/water': 7}`,
            `pages?.[6]?.answerNo === 1 && pages?.[7]?.answerNo === 0 && pages?.[28]?.answerNo === 0`,
            userId,
            consumptions,
          )

          return [
            {
              'Hybride warmtepomp': 5,
              'Warmtepomp lucht/lucht': 6,
              'Warmtepomp lucht/water': 7,
              // 'Warmtepomp water/water': 8,
              // Warmtenet: 14,
            },
          ]
        }
        setCardsOptionLog(
          'Verwarming',
          `{'Warmtepomp lucht/lucht': 6, 'Warmtepomp lucht/water': 7, 'Warmtepomp bodem/water': 9,}`,
          `pages?.[6]?.answerNo === 1 && pages?.[7]?.answerNo === 0 && pages?.[28]?.answerNo != 0`,
          userId,
          consumptions,
        )

        return [
          {
            'Hybride warmtepomp': 5,
            'Warmtepomp lucht/lucht': 6,
            'Warmtepomp lucht/water': 7,
            'Warmtepomp bodem/water': 9,
            // 'Warmtepomp water/water': 8,
            // Warmtenet: 14,
          },
        ]
      }
      // if (pages?.[7]?.answerNo === 1) {
      //   // if (consumptions < consumptionThreshold) {
      //   //   if (pages?.[28]?.answerNo === 0) {
      //   //     setCardsOptionLog(
      //   //       'Verwarming',
      //   //       `{'Warmtepomp lucht/lucht': 6, 'Warmtepomp lucht/water': 7, 'Warmtepomp water/water': 8,}`,
      //   //       `pages?.[6]?.answerNo === 1 && pages?.[7]?.answerNo === 1 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
      //   //       userId,
      //   //       consumptions,
      //   //     )

      //   //     return [
      //   //       {
      //   //         'Warmtepomp lucht/lucht': 6,
      //   //         'Warmtepomp lucht/water': 7,
      //   //         'Warmtepomp water/water': 8,
      //   //         // Warmtenet: 14,
      //   //       },
      //   //     ]
      //   //   }
      //   //   setCardsOptionLog(
      //   //     'Verwarming',
      //   //     `{'Warmtepomp lucht/lucht': 6, 'Warmtepomp lucht/water': 7, 'Warmtepomp bodem/water': 9, 'Warmtepomp water/water': 8,}`,
      //   //     `pages?.[6]?.answerNo === 1 && pages?.[7]?.answerNo === 1 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
      //   //     userId,
      //   //     consumptions,
      //   //   )

      //   //   return [
      //   //     {
      //   //       'Warmtepomp lucht/lucht': 6,
      //   //       'Warmtepomp lucht/water': 7,
      //   //       'Warmtepomp bodem/water': 9,
      //   //       'Warmtepomp water/water': 8,
      //   //       // Warmtenet: 14,
      //   //     },
      //   //   ]
      //   // }
      //   if (pages?.[28]?.answerNo === 0) {
      //     setCardsOptionLog(
      //       'Verwarming',
      //       `{'Hybride warmtepomp': 5, 'Warmtepomp lucht/lucht': 6, 'Warmtepomp lucht/water': 7, 'Warmtepomp water/water': 8,}`,
      //       `pages?.[6]?.answerNo === 1 && pages?.[7]?.answerNo === 1 && consumptions > ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
      //       userId,
      //       consumptions,
      //     )

      //     return [
      //       {
      //         'Hybride warmtepomp': 5,
      //         'Warmtepomp lucht/lucht': 6,
      //         'Warmtepomp lucht/water': 7,
      //         'Warmtepomp water/water': 8,
      //         // Warmtenet: 14,
      //       },
      //     ]
      //   }
      //   setCardsOptionLog(
      //     'Verwarming',
      //     `{'Hybride warmtepomp': 5, 'Warmtepomp lucht/lucht': 6, 'Warmtepomp lucht/water': 7, 'Warmtepomp bodem/water': 9, 'Warmtepomp water/water': 8}`,
      //     `pages?.[6]?.answerNo === 1 && pages?.[7]?.answerNo === 1 && consumptions > ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
      //     userId,
      //     consumptions,
      //   )

      //   return [
      //     {
      //       'Hybride warmtepomp': 5,
      //       'Warmtepomp lucht/lucht': 6,
      //       'Warmtepomp lucht/water': 7,
      //       'Warmtepomp bodem/water': 9,
      //       'Warmtepomp water/water': 8,
      //       // Warmtenet: 14,
      //     },
      //   ]
      // }
      setCardsOptionLog(
        'Verwarming',
        `{'Warmtepomp lucht/lucht': 6, 'Warmtepomp lucht/water': 7, 'Warmtepomp water/water': 8, 'Warmtepomp bodem/water': 9,}`,
        `pages?.[6]?.answerNo === 1 && pages?.[7]?.answerNo === 1 && (pages?.[7]?.answerNo != 0 || pages?.[7]?.answerNo !=  1)`,
        userId,
        consumptions,
      )

      return [
        {
          'Warmtepomp lucht/lucht': 6,
          'Warmtepomp lucht/water': 7,
          'Warmtepomp water/water': 8,
          'Warmtepomp bodem/water': 9,
        },
      ]
    }
    if (pages?.[6]?.answerNo === 2) {
      if (pages?.[7]?.answerNo === 0 || pages?.[7]?.answerNo === 1) {
        // if (consumptions < consumptionThreshold) {
        //   if (pages?.[28]?.answerNo === 0) {
        //     setCardsOptionLog(
        //       'Verwarming',
        //       `{'Warmtepomp lucht/lucht': 6,'Warmtepomp lucht/water': 7, }`,
        //       `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 0 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
        //       userId,
        //       consumptions,
        //     )

        //     return [
        //       {
        //         // Condensatieketel: 12,
        //         // 'Hybride ketel': 13,
        //         'Warmtepomp lucht/lucht': 6,
        //         'Warmtepomp lucht/water': 7,
        //         // 'Warmtepomp water/water': 8,
        //         // Warmtenet: 14,
        //       },
        //     ]
        //   }
        //   setCardsOptionLog(
        //     'Verwarming',
        //     `{ Warmtepomp lucht/lucht': 6,'Warmtepomp lucht/water': 7,'Warmtepomp bodem/water': 9,}`,
        //     `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 0 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
        //     userId,
        //     consumptions,
        //   )

        //   return [
        //     {
        //       // Condensatieketel: 12,
        //       // 'Hybride ketel': 13,
        //       'Warmtepomp lucht/lucht': 6,
        //       'Warmtepomp lucht/water': 7,
        //       // 'Warmtepomp water/water': 8,
        //       'Warmtepomp bodem/water': 9,
        //       // Warmtenet: 14,
        //     },
        //   ]
        // }
        if (pages?.[28]?.answerNo === 0) {
          setCardsOptionLog(
            'Verwarming',
            `{Kachels: 10, }`,
            `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 0 && consumptions > ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
            userId,
            consumptions,
          )

          return [
            {
              // Kachels: 10,
              Condensatieketel: 12,
              'Hybride warmtepomp': 5,
              // 'Hybride ketel': 13,
              // 'Warmtepomp bodem/water': 9,
              // 'Warmtepomp water/water': 8,
              'Warmtepomp lucht/water': 7,
              'Warmtepomp lucht/lucht': 6,
              // Warmtenet: 14,
            },
          ]
        }
        setCardsOptionLog(
          'Verwarming',
          `{ Kachels: 10, }`,
          `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 0 && consumptions > ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
          userId,
          consumptions,
        )

        return [
          {
            // Kachels: 10,
            Condensatieketel: 12,
            'Hybride warmtepomp': 5,
            // 'Hybride ketel': 13,
            'Warmtepomp bodem/water': 9,
            // 'Warmtepomp water/water': 8,
            'Warmtepomp lucht/water': 7,
            'Warmtepomp lucht/lucht': 6,
            // Warmtenet: 14,
          },
        ]
      }
      // if (pages?.[7]?.answerNo === 1) {
      //   // if (consumptions < consumptionThreshold) {
      //   //   if (pages?.[28]?.answerNo === 0) {
      //   //     setCardsOptionLog(
      //   //       'Verwarming',
      //   //       `{ 'Warmtepomp lucht/water': 7}`,
      //   //       `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 1 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
      //   //       userId,
      //   //       consumptions,
      //   //     )

      //   //     return [
      //   //       {
      //   //         // 'Warmtepomp lucht/lucht': 6,
      //   //         'Warmtepomp lucht/water': 7,
      //   //         // 'Warmtepomp water/water': 8,
      //   //         // Warmtenet: 14,
      //   //       },
      //   //     ]
      //   //   }
      //   //   setCardsOptionLog(
      //   //     'Verwarming',
      //   //     `{'Warmtepomp lucht/water': 7, 'Warmtepomp bodem/water': 9,}`,
      //   //     `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 1 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
      //   //     userId,
      //   //     consumptions,
      //   //   )

      //   //   return [
      //   //     {
      //   //       // 'Warmtepomp lucht/lucht': 6,
      //   //       'Warmtepomp lucht/water': 7,
      //   //       // 'Warmtepomp water/water': 8,
      //   //       'Warmtepomp bodem/water': 9,
      //   //       // Warmtenet: 14,
      //   //     },
      //   //   ]
      //   // }
      //   if (pages?.[28]?.answerNo === 0) {
      //     setCardsOptionLog(
      //       'Verwarming',
      //       `{ Condensatieketel: 12}`,
      //       `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 1 && consumptions > ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
      //       userId,
      //       consumptions,
      //     )

      //     return [
      //       {
      //         Condensatieketel: 12,
      //         // 'Warmtepomp water/water': 8,
      //         // 'Warmtepomp lucht/water': 7,
      //         // 'Warmtepomp lucht/lucht': 6,
      //         // Warmtenet: 14,
      //       },
      //     ]
      //   }
      //   setCardsOptionLog(
      //     'Verwarming',
      //     `{Condensatieketel: 12}`,
      //     `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 1 && consumptions > ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
      //     userId,
      //     consumptions,
      //   )

      //   return [
      //     {
      //       Condensatieketel: 12,
      //       // 'Warmtepomp bodem/water': 9,
      //       // 'Warmtepomp water/water': 8,
      //       // 'Warmtepomp lucht/water': 7,
      //       // 'Warmtepomp lucht/lucht': 6,
      //       // Warmtenet: 14,
      //     },
      //   ]
      // }
      if (pages?.[7]?.answerNo === 2) {
        // if (consumptions < consumptionThreshold) {
        //   if (pages?.[28]?.answerNo === 0) {
        //     setCardsOptionLog(
        //       'Verwarming',
        //       `{'Warmtepomp lucht/water': 7}`,
        //       `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 2 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
        //       userId,
        //       consumptions,
        //     )

        //     return [
        //       {
        //         // 'Warmtepomp lucht/lucht': 6,
        //         'Warmtepomp lucht/water': 7,
        //         // 'Warmtepomp water/water': 8,
        //         // Warmtenet: 14,
        //       },
        //     ]
        //   }
        //   setCardsOptionLog(
        //     'Verwarming',
        //     `{'Warmtepomp lucht/water': 7, 'Warmtepomp bodem/water': 9}`,
        //     `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 2 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
        //     userId,
        //     consumptions,
        //   )

        //   return [
        //     {
        //       // 'Warmtepomp lucht/lucht': 6,
        //       'Warmtepomp lucht/water': 7,
        //       // 'Warmtepomp water/water': 8,
        //       'Warmtepomp bodem/water': 9,
        //       // Warmtenet: 14,
        //     },
        //   ]
        // }
        if (pages?.[28]?.answerNo === 0) {
          setCardsOptionLog(
            'Verwarming',
            `{Condensatieketel: 12, }`,
            `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 2 && consumptions > ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
            userId,
            consumptions,
          )

          return [
            {
              // Condensatieketel: 12,
              'Hybride warmtepomp': 5,
              // 'Hybride ketel': 13,
              // 'Warmtepomp bodem/water': 9,
              // 'Warmtepomp water/water': 8,
              'Warmtepomp lucht/water': 7,
              'Warmtepomp lucht/lucht': 6,
              // Warmtenet: 14,
            },
          ]
        }
        setCardsOptionLog(
          'Verwarming',
          `{Condensatieketel: 12, }`,
          `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 2 && consumptions > ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
          userId,
          consumptions,
        )

        return [
          {
            // Condensatieketel: 12,
            'Hybride warmtepomp': 5,
            // 'Hybride ketel': 13,
            'Warmtepomp bodem/water': 9,
            // 'Warmtepomp water/water': 8,
            'Warmtepomp lucht/water': 7,
            'Warmtepomp lucht/lucht': 6,
            // Warmtenet: 14,
          },
        ]
      }
      if (pages?.[7]?.answerNo === 3) {
        // if (consumptions < consumptionThreshold) {
        //   if (pages?.[28]?.answerNo === 0) {
        //     setCardsOptionLog(
        //       'Verwarming',
        //       `{'Warmtepomp lucht/water': 7}`,
        //       `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 3 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
        //       userId,
        //       consumptions,
        //     )

        //     return [
        //       {
        //         // 'Warmtepomp lucht/lucht': 6,
        //         'Warmtepomp lucht/water': 7,
        //         // 'Warmtepomp water/water': 8,
        //         // Warmtenet: 14,
        //       },
        //     ]
        //   }
        //   setCardsOptionLog(
        //     'Verwarming',
        //     `{'Warmtepomp lucht/water': 7, 'Warmtepomp bodem/water': 9}`,
        //     `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 3 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
        //     userId,
        //     consumptions,
        //   )

        //   return [
        //     {
        //       // 'Warmtepomp lucht/lucht': 6,
        //       'Warmtepomp lucht/water': 7,
        //       // 'Warmtepomp water/water': 8,
        //       'Warmtepomp bodem/water': 9,
        //       // Warmtenet: 14,
        //     },
        //   ]
        // }
        if (pages?.[28]?.answerNo === 0) {
          setCardsOptionLog(
            'Verwarming',
            `{'Hybride ketel': 3}`,
            `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 3 && consumptions > ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
            userId,
            consumptions,
          )

          return [
            {
              // 'Hybride ketel': 3,
              // 'Warmtepomp bodem/water': 9,
              // 'Warmtepomp water/water': 8,
              'Warmtepomp lucht/water': 7,
              'Warmtepomp lucht/lucht': 6,
              // Warmtenet: 14,
            },
          ]
        }
        setCardsOptionLog(
          'Verwarming',
          `{'Hybride ketel': 3}`,
          `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 3 && consumptions > ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
          userId,
          consumptions,
        )

        return [
          {
            // 'Hybride ketel': 3,
            'Warmtepomp bodem/water': 9,
            // 'Warmtepomp water/water': 8,
            'Warmtepomp lucht/water': 7,
            'Warmtepomp lucht/lucht': 6,
            // Warmtenet: 14,
          },
        ]
      }
    }
    if (pages?.[6]?.answerNo === 3) {
      // if (pages?.[7]?.answerNo === 0) {
      //   setCardsOptionLog(
      //     'Verwarming',
      //     `{ }`,
      //     `pages?.[6]?.answerNo === 3 && pages?.[7]?.answerNo === 0`,
      //     userId,
      //     consumptions,
      //   )

      //   return [
      //     {
      //       Hout: 16,
      //       Pellets: 15,
      //       // Warmtenet: 14,
      //       'Warmtepomp lucht/water': 7,
      //       'Warmtepomp water/water': 8,
      //       'Warmtepomp bodem/water': 9,
      //     },
      //   ]
      // }
      if (pages?.[7]?.answerNo === 1) {
        // if (consumptions < consumptionThreshold) {
        //   if (pages?.[28]?.answerNo === 0) {
        //     setCardsOptionLog(
        //       'Verwarming',
        //       `{'Warmtepomp lucht/lucht': 6, 'Warmtepomp lucht/water': 7}`,
        //       `pages?.[6]?.answerNo === 3 && pages?.[7]?.answerNo === 1 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
        //       userId,
        //       consumptions,
        //     )

        //     return [
        //       {
        //         // Warmtenet: 14,
        //         'Warmtepomp lucht/lucht': 6,
        //         'Warmtepomp lucht/water': 7,
        //         // 'Warmtepomp water/water': 8,
        //       },
        //     ]
        //   }
        //   setCardsOptionLog(
        //     'Verwarming',
        //     `{'Warmtepomp lucht/lucht': 6, 'Warmtepomp lucht/water': 7, 'Warmtepomp bodem/water': 9}`,
        //     `pages?.[6]?.answerNo === 3 && pages?.[7]?.answerNo === 1 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
        //     userId,
        //     consumptions,
        //   )

        //   return [
        //     {
        //       // Warmtenet: 14,
        //       'Warmtepomp lucht/lucht': 6,
        //       'Warmtepomp lucht/water': 7,
        //       // 'Warmtepomp water/water': 8,
        //       'Warmtepomp bodem/water': 9,
        //     },
        //   ]
        // }
        if (pages?.[28]?.answerNo === 0) {
          setCardsOptionLog(
            'Verwarming',
            `{Pellets: 15}`,
            `pages?.[6]?.answerNo === 3 && pages?.[7]?.answerNo === 1 && consumptions > ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
            userId,
            consumptions,
          )

          return [
            {
              // Pellets: 15,
              // Warmtenet: 14,
              // 'Warmtepomp water/water': 8,
              'Warmtepomp lucht/water': 7,
              'Warmtepomp lucht/lucht': 6,
            },
          ]
        }
        setCardsOptionLog(
          'Verwarming',
          `{Pellets: 15}`,
          `pages?.[6]?.answerNo === 3 && pages?.[7]?.answerNo === 1 && consumptions > ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
          userId,
          consumptions,
        )

        return [
          {
            // Pellets: 15,
            // Warmtenet: 14,
            'Warmtepomp bodem/water': 9,
            // 'Warmtepomp water/water': 8,
            'Warmtepomp lucht/water': 7,
            'Warmtepomp lucht/lucht': 6,
          },
        ]
      }
      if (pages?.[7]?.answerNo === 2) {
        // if (consumptions < consumptionThreshold) {
        //   if (pages?.[28]?.answerNo === 0) {
        //     setCardsOptionLog(
        //       'Verwarming',
        //       `{'Warmtepomp lucht/lucht': 6, 'Warmtepomp lucht/water': 7}`,
        //       `pages?.[6]?.answerNo === 3 && pages?.[7]?.answerNo === 2 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
        //       userId,
        //       consumptions,
        //     )

        //     return [
        //       {
        //         // Warmtenet: 14,
        //         'Warmtepomp lucht/lucht': 6,
        //         'Warmtepomp lucht/water': 7,
        //         // 'Warmtepomp water/water': 8,
        //       },
        //     ]
        //   }
        //   setCardsOptionLog(
        //     'Verwarming',
        //     `{'Warmtepomp lucht/lucht': 6, 'Warmtepomp lucht/water': 7, 'Warmtepomp bodem/water': 9}`,
        //     `pages?.[6]?.answerNo === 3 && pages?.[7]?.answerNo === 2 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
        //     userId,
        //     consumptions,
        //   )

        //   return [
        //     {
        //       // Warmtenet: 14,
        //       'Warmtepomp lucht/lucht': 6,
        //       'Warmtepomp lucht/water': 7,
        //       // 'Warmtepomp water/water': 8,
        //       'Warmtepomp bodem/water': 9,
        //     },
        //   ]
        // }
        if (pages?.[28]?.answerNo === 0) {
          setCardsOptionLog(
            'Verwarming',
            `{Hout: 16}`,
            `pages?.[6]?.answerNo === 3 && pages?.[7]?.answerNo === 2 && consumptions > ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
            userId,
            consumptions,
          )

          return [
            {
              // Hout: 16,
              // Warmtenet: 14,
              // 'Warmtepomp water/water': 8,
              'Warmtepomp lucht/water': 7,
              'Warmtepomp lucht/lucht': 6,
            },
          ]
        }
        setCardsOptionLog(
          'Verwarming',
          `{Hout: 16}`,
          `pages?.[6]?.answerNo === 3 && pages?.[7]?.answerNo === 2 && consumptions > ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
          userId,
          consumptions,
        )

        return [
          {
            // Hout: 16,
            // Warmtenet: 14,
            'Warmtepomp bodem/water': 9,
            // 'Warmtepomp water/water': 8,
            'Warmtepomp lucht/water': 7,
            'Warmtepomp lucht/lucht': 6,
          },
        ]
      }
    }
    setCardsOptionLog(
      'Verwarming',
      `{Hout: 16, Pellets: 15, 'Warmtepomp lucht/water': 7, 'Warmtepomp water/water': 8, 'Warmtepomp bodem/water': 9,}`,
      `pages?.[6]?.answerNo === 0 || pages?.[6]?.answerNo === 1 || pages?.[6]?.answerNo === 2 || pages?.[6]?.answerNo === 3`,
      userId,
      consumptions,
    )

    return [
      {
        Hout: 16,
        Pellets: 15,
        // Warmtenet: 14,
        'Warmtepomp lucht/water': 7,
        'Warmtepomp water/water': 8,
        'Warmtepomp bodem/water': 9,
      },
    ]
  }
  if (title === 'Ventilatie') {
    if (pages?.[21]?.answerNo === 0) {
      setCardsOptionLog(
        'Ventilatie',
        `{'Vraaggestuurd mechanisch (C)': 3, 'Balansventilatie met warmterecuperatie (D)': 4}`,
        `pages?.[21]?.answerNo === 0`,
        userId,
        consumptions,
      )

      return [
        {
          'Vraaggestuurd mechanisch (C)': 3,
          'Balansventilatie met warmterecuperatie (D)': 4,
        },
      ]
    }
    if (pages?.[21]?.answerNo === 1 || pages?.[21]?.answerNo === 2) {
      setCardsOptionLog(
        'Ventilatie',
        `{'Vraaggestuurd mechanisch (C)': 3, 'Balansventilatie met warmterecuperatie (D)': 4}`,
        `pages?.[21]?.answerNo === 1 || pages?.[21]?.answerNo === 2`,
        userId,
        consumptions,
      )

      return [
        {
          'Vraaggestuurd mechanisch (C)': 3,
          'Balansventilatie met warmterecuperatie (D)': 4,
        },
      ]
    }
    if (pages?.[21]?.answerNo === 3) {
      setCardsOptionLog(
        'Ventilatie',
        `{'Balansventilatie met warmterecuperatie (D)': 4}`,
        `pages?.[21]?.answerNo === 3`,
        userId,
        consumptions,
      )

      return [
        {
          'Balansventilatie met warmterecuperatie (D)': 4,
        },
      ]
    }
    setCardsOptionLog(
      'Ventilatie',
      `{'Natuurlijk met raamroosters': 1, 'Minimaal mechanisch (C)': 2, 'Vraaggestuurd mechanisch (C)': 3, 'Balansventilatie met warmterecuperatie (D)': 4}`,
      `pages?.[21]?.answerNo !=0 || pages?.[21]?.answerNo !=1 || pages?.[21]?.answerNo !=2 || pages?.[21]?.answerNo !=3`,
      userId,
      consumptions,
    )
    return [
      {
        'Natuurlijk met raamroosters': 1,
        'Minimaal mechanisch (C)': 2,
        'Vraaggestuurd mechanisch (C)': 3,
        'Balansventilatie met warmterecuperatie (D)': 4,
      },
    ]
  }

  if (title === 'Warm water') {
    if (pages?.[11]?.answerNo === 0) {
      setCardsOptionLog(
        'Warm water',
        `{Idem als verwarming: 0}`,
        `pages?.[11]?.answerNo === 0`,
        userId,
        consumptions,
      )

      return [{ 'Idem als verwarming': 0 }]
    }
    if (pages?.[11]?.answerNo === 3 || pages?.[11]?.answerNo === 4) {
      setCardsOptionLog(
        'Warm water',
        `{Warmtepompboiler: 2 }`,
        `(pages?.[11]?.answerNo === 3 || pages?.[11]?.answerNo === 4`,
        userId,
        consumptions,
      )

      return [{ Warmtepompboiler: 2 }]
    }
    setCardsOptionLog(
      'Warm water',
      `{'Idem als verwarming': 0, Zonneboiler: 1, Warmtepompboiler: 2, Gasdoorstromer: 4}`,
      `pages?.[11]?.answerNo != 0 || pages?.[11]?.answerNo != 3 || pages?.[11]?.answerNo != 4`,
      userId,
      consumptions,
    )

    return [
      {
        // Gasdoorstromer: 4,
        // Zonneboiler: 1,
        Warmtepompboiler: 2,
        // 'Idem als verwarming': 0,
      },
    ]
  }

  if (title === 'Zonnepanelen') {
    setCardsOptionLog(
      'Zonnepanelen',
      `{'Wel zonnepanelen': 0 }`,
      `Default`,
      userId,
      consumptions,
    )

    return [{ 'Wel zonnepanelen': 0 }]
  }

  if (title === 'Groendak') {
    setCardsOptionLog(
      'Groendak',
      `{'Wel een groendak': 0}`,
      `Default`,
      userId,
      consumptions,
    )

    return [{ 'Wel een groendak': 0 }]
  }

  if (title === 'Zonnewering') {
    if (
      pages?.[6]?.answerNo === 1 &&
      (pages?.[7]?.answerNo === 2 ||
        pages?.[7]?.answerNo === 3 ||
        pages?.[7]?.answerNo === 4 ||
        pages?.[7]?.answerNo === 5)
    ) {
      setCardsOptionLog(
        'Zonnewering',
        `{'Zorg voor zonnewering': 2, 'Plaats geveltuin': 3, Koeling: 4}`,
        `pages?.[6]?.answerNo === 1 && (pages?.[7]?.answerNo === 2 || pages?.[7]?.answerNo === 3 || pages?.[7]?.answerNo === 4 || pages?.[7]?.answerNo === 6)`,
        userId,
        consumptions,
      )

      return [{ 'Zorg voor zonnewering': 2, 'Plaats geveltuin': 3, Koeling: 4 }]
    }
    setCardsOptionLog(
      'Zonnewering',
      `{'Zorg voor zonnewering': 2, 'Plaats geveltuin': 3}`,
      `pages?.[6]?.answerNo != 1 && (pages?.[7]?.answerNo != 2 || pages?.[7]?.answerNo != 3 || pages?.[7]?.answerNo != 4 || pages?.[7]?.answerNo != 6)`,
      userId,
      consumptions,
    )

    return [{ 'Zorg voor zonnewering': 2, 'Plaats geveltuin': 3 }]
  }

  if (title === 'Regenput') {
    setCardsOptionLog(
      'Regenput',
      `{'Wel een regenwaterput': 0}`,
      `Default`,
      userId,
      consumptions,
    )
    if(pages?.[1]?.answerNo == 2 && pages?.[29]?.answerNo === 1 ){
      return [{ 'Plaats een regenwaterzak': 0 }]
    }else if((pages?.[1]?.answerNo == 0 || pages?.[1]?.answerNo == 1) && pages?.[29]?.answerNo === 1 ){
      return [{ 'Plaats een regenwaterput': 0 }]
    } else {
      return [{ 'Wel een regenwaterput': 0 }]
    }
  }

  if (pageIndex == 30) {
    setCardsOptionLog(
      'Vergroenen',
      `{ Nee: 1}`,
      `Default`,
      userId,
      consumptions,
    )

    return [{ "Onthard": 1 }]
  }
  return [{}]
}

export function setCardsOptionPreselectLog(title, answer_id, reason, userId) {
  const data = []
  let card
  let timeout
  if (title === 'Hoofddak') {
    ;(card = 'card1'), (timeout = 5)
  }
  if (title === 'Bijdak') {
    ;(card = 'card2'), (timeout = 6)
  }
  if (title === 'Ramen leefruimte') {
    ;(card = 'card3'), (timeout = 7)
  }
  if (title === 'Ramen slaapkamers') {
    ;(card = 'card4'), (timeout = 8)
  }
  if (title === 'Voorgevel') {
    ;(card = 'card5'), (timeout = 7)
  }
  if (title === 'Achter- en zijgevels') {
    ;(card = 'card6'), (timeout = 8)
  }
  if (title === 'Vloerisolatie') {
    ;(card = 'card7'), (timeout = 9)
  }
  if (title === 'Verwarming') {
    ;(card = 'card8'), (timeout = 10)
  }
  if (title === 'Ventilatie') {
    ;(card = 'card9'), (timeout = 11)
  }
  if (title === 'Warm water') {
    ;(card = 'card10'), (timeout = 12)
  }
  if (title === 'Zonnepanelen') {
    ;(card = 'card11'), (timeout = 13)
  }
  if (title === 'Groendak') {
    ;(card = 'card12'), (timeout = 14)
  }
  if (title === 'Zonnewering') {
    ;(card = 'card13'), (timeout = 15)
  }
  if (title === 'Regenput') {
    ;(card = 'card14'), (timeout = 16)
  }
  if (title === 'Vergroenen') {
    ;(card = 'card15'), (timeout = 17)
  }

  const statusText = `Card's Option Preselection\r\n***************************
  => Answer Preselected : ${answer_id}
  => Reason : ${reason}\r\n\r\n`

  data.push({
    logs: statusText,
    card_id: card,
    userId,
  })
  setTimeout(() => {
    axios.post(`${APP_URL}/logs/addCardsLogs`, { data })
  }, 8000 + 1000 * timeout)
}

export function getModalSelectedOption(title, pages, pageIndex, userId, log) {
  const consumptions = Number(localStorage.getItem('energyGasNew'))
  const paramsResponse = JSON.parse(localStorage.getItem('parameters'))
  const parametersData = paramsResponse?.data?.data?.[0] || []
  const consumptionThreshold =
    parametersData.find(
      (param) => param?.name === 'Plafond verbruik voor warmtepomp',
    )?.value || 0

  if (title === 'Hoofddak') {
    setCardsOptionPreselectLog(title, 3, 'Default', userId, log)
    return 3
  }
  if (title === 'Bijdak') {
    setCardsOptionPreselectLog(title, 3, 'Default', userId, log)
    return 3
  }
  if (title === 'Ramen leefruimte') {
    setCardsOptionPreselectLog(title, 3, 'Default', userId, log)
    return 3
  }
  if (title === 'Ramen slaapkamers') {
    setCardsOptionPreselectLog(title, 3, 'Default', userId, log)

    return 3
  }
  if (title === 'Voorgevel') {
    setCardsOptionPreselectLog(title, 0, 'Default', userId, log)

    return 0
  }
  if (title === 'Achter- en zijgevels') {
    setCardsOptionPreselectLog(title, 0, 'Default', userId, log)

    return 0
  }
  if (title === 'Vloerisolatie') {
    setCardsOptionPreselectLog(title, 0, 'Default', userId, log)
    return 0
  }
  if (title === 'Verwarming') {
    if (pages?.[6]?.answerNo === 0) {
      if (pages?.[7]?.answerNo === 0 || pages?.[7]?.answerNo === 1) {
        if (consumptions > consumptionThreshold) {
          setCardsOptionPreselectLog(
            title,
            2,
            `pages?.[6]?.answerNo === 0 && pages?.[7]?.answerNo === 0 && consumptions > ${consumptionThreshold}`,
            userId,
            log,
          )
          return 2
        }
        if (pages?.[28]?.answerNo === 0) {
          setCardsOptionPreselectLog(
            title,
            6,
            `pages?.[6]?.answerNo === 0 && pages?.[7]?.answerNo === 0 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
            userId,
            log,
          )
          return 6
        }
        setCardsOptionPreselectLog(
          title,
          9,
          `pages?.[6]?.answerNo === 0 && pages?.[7]?.answerNo === 0 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
          userId,
          log,
        )
        return 9
      }
      if (pages?.[7]?.answerNo === 2) {
        if (consumptions > consumptionThreshold) {
          setCardsOptionPreselectLog(
            title,
            5,
            `pages?.[6]?.answerNo === 0 && (pages?.[7]?.answerNo === 1 || pages?.[7]?.answerNo === 2) && consumptions > ${consumptionThreshold}`,
            userId,
            log,
          )
          return 5
        }
        if (pages?.[28]?.answerNo === 0) {
          setCardsOptionPreselectLog(
            title,
            7,
            `pages?.[6]?.answerNo === 0 && (pages?.[7]?.answerNo === 1 || pages?.[7]?.answerNo === 2) && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
            userId,
            log,
          )
          return 7
        }
        setCardsOptionPreselectLog(
          title,
          9,
          `pages?.[6]?.answerNo === 0 && (pages?.[7]?.answerNo === 1 || pages?.[7]?.answerNo === 2) && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
          userId,
          log,
        )
        return 9
      }
      if (pages?.[7]?.answerNo === 3) {
        // if (consumptions > consumptionThreshold) {
        //   setCardsOptionPreselectLog(
        //     title,
        //     3,
        //     `pages?.[6]?.answerNo === 0 && pages?.[7]?.answerNo === 3 && consumptions > ${consumptionThreshold}`,
        //     userId,
        //     log,
        //   )
        //   return 3 // Changed from 5 for hybrid ketel
        // }
        if (pages?.[28]?.answerNo === 0) {
          setCardsOptionPreselectLog(
            title,
            7,
            `pages?.[6]?.answerNo === 0 && pages?.[7]?.answerNo === 3 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
            userId,
            log,
          )
          return 7
        }
        setCardsOptionPreselectLog(
          title,
          9,
          `pages?.[6]?.answerNo === 0 && pages?.[7]?.answerNo === 3 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
          userId,
          log,
        )

        return 9
      }
      setCardsOptionPreselectLog(
        title,
        8,
        'pages?.[6]?.answerNo === 0 && pages?.[7]?.answerNo != 0 && pages?.[7]?.answerNo != 1 && pages?.[7]?.answerNo != 2 && pages?.[7]?.answerNo != 3 ',
        userId,
        log,
      )
      return 8
    }
    if (pages?.[6]?.answerNo === 1) {
      if (pages?.[7]?.answerNo === 0) {
        if (pages?.[28]?.answerNo === 0) {
          setCardsOptionPreselectLog(
            title,
            6,
            'pages?.[6]?.answerNo === 1 && pages?.[7]?.answerNo === 0 && pages?.[28]?.answerNo === 0',
            userId,
            log,
          )

          return 6
        }
        setCardsOptionPreselectLog(
          title,
          9,
          'pages?.[6]?.answerNo === 1 && pages?.[7]?.answerNo === 0 && pages?.[28]?.answerNo != 0',
          userId,
          log,
        )

        return 9
      }
      // if (pages?.[7]?.answerNo === 4) {
      //   if (pages?.[28]?.answerNo === 0) {
      //     setCardsOptionPreselectLog(
      //       title,
      //       6,
      //       'pages?.[6]?.answerNo === 1 && pages?.[7]?.answerNo === 4 && pages?.[28]?.answerNo === 0',
      //       userId,
      //       log,
      //     )
      //     return 6
      //   }
      //   setCardsOptionPreselectLog(
      //     title,
      //     9,
      //     'pages?.[6]?.answerNo === 1 && pages?.[7]?.answerNo === 4 && pages?.[28]?.answerNo != 0',
      //     userId,
      //     log,
      //   )
      //   return 9
      // }
      // if (pages?.[7]?.answerNo === 5) {
      //   if (consumptions > consumptionThreshold) {
      //     setCardsOptionPreselectLog(
      //       title,
      //       6,
      //       `pages?.[6]?.answerNo === 1 && pages?.[7]?.answerNo === 5 && consumptions > ${consumptionThreshold}`,
      //       userId,
      //       log,
      //     )
      //     return 6
      //   }
      //   if (pages?.[28]?.answerNo === 0) {
      //     setCardsOptionPreselectLog(
      //       title,
      //       7,
      //       `pages?.[6]?.answerNo === 1 && pages?.[7]?.answerNo === 5 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
      //       userId,
      //       log,
      //     )
      //     return 7
      //   }
      //   setCardsOptionPreselectLog(
      //     title,
      //     7,
      //     `pages?.[6]?.answerNo === 1 && pages?.[7]?.answerNo === 5 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
      //     userId,
      //     log,
      //   )
      //   return 9
      // }
      setCardsOptionPreselectLog(
        title,
        7,
        'pages?.[6]?.answerNo === 1 && pages?.[7]?.answerNo != 0 && pages?.[7]?.answerNo != 4 && pages?.[7]?.answerNo != 5',
        userId,
        log,
      )

      return 7
    }
    if (pages?.[6]?.answerNo === 2) {
      if (
        pages?.[7]?.answerNo === 0 ||
        pages?.[7]?.answerNo === 10 ||
        pages?.[7]?.answerNo === 1 ||
        pages?.[7]?.answerNo === 11
      ) {
        // console.log('consumptions', consumptions)
        // console.log('consumptionThreshold', consumptionThreshold)
        // console.log('consumptions', consumptions)
        if (consumptions > consumptionThreshold) {
          setCardsOptionPreselectLog(
            title,
            12,
            `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 10 && consumptions > ${consumptionThreshold}`,
            userId,
            log,
          )
          return 2 // can be 2
        }
        if (pages?.[28]?.answerNo === 0) {
          setCardsOptionPreselectLog(
            title,
            6,
            `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 10 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
            userId,
            log,
          )
          return 6
        }
        setCardsOptionPreselectLog(
          title,
          9,
          `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 10 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
          userId,
          log,
        )
        return 9
      }
      // if (pages?.[7]?.answerNo === 1 || pages?.[7]?.answerNo === 11) {
      //   if (consumptions > consumptionThreshold) {
      //     setCardsOptionPreselectLog(
      //       title,
      //       12,
      //       `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 11 && consumptions > ${consumptionThreshold}`,
      //       userId,
      //       log,
      //     )
      //     return 12
      //   }
      //   if (pages?.[28]?.answerNo === 0) {
      //     setCardsOptionPreselectLog(
      //       title,
      //       7,
      //       `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 11 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
      //       userId,
      //       log,
      //     )
      //     return 7
      //   }
      //   setCardsOptionPreselectLog(
      //     title,
      //     9,
      //     `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 11 && consumptions > ${consumptionThreshold}`,
      //     userId,
      //     log,
      //   )
      //   return 9
      // }
      if (pages?.[7]?.answerNo === 2 || pages?.[7]?.answerNo === 12) {
        if (consumptions > consumptionThreshold) {
          setCardsOptionPreselectLog(
            title,
            5,
            `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 12 && consumptions > ${consumptionThreshold}`,
            userId,
            log,
          )

          return 5
        }
        if (pages?.[28]?.answerNo === 0) {
          setCardsOptionPreselectLog(
            title,
            7,
            `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 12 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
            userId,
            log,
          )

          return 7
        }
        setCardsOptionPreselectLog(
          title,
          9,
          `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 12 && consumptions > ${consumptionThreshold}`,
          userId,
          log,
        )

        return 9
      }
      if (pages?.[7]?.answerNo === 3 || pages?.[7]?.answerNo === 13) {
        // if (consumptions > consumptionThreshold) {
        //   setCardsOptionPreselectLog(
        //     title,
        //     13,
        //     `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 13 && consumptions > ${consumptionThreshold}`,
        //     userId,
        //     log,
        //   )

        //   return 13
        // }
        if (pages?.[28]?.answerNo === 0) {
          setCardsOptionPreselectLog(
            title,
            7,
            `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 13 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
            userId,
            log,
          )

          return 7
        }
        setCardsOptionPreselectLog(
          title,
          9,
          `pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo === 13 && consumptions > ${consumptionThreshold}`,
          userId,
          log,
        )

        return 9
      }
      setCardsOptionPreselectLog(
        title,
        10,
        'pages?.[6]?.answerNo === 2 && pages?.[7]?.answerNo != 10 && pages?.[7]?.answerNo != 11 && pages?.[7]?.answerNo != 12 && pages?.[7]?.answerNo != 13 ',
        userId,
        log,
      )

      return 10
    }
    if (pages?.[6]?.answerNo === 3) {
      // if (pages?.[7]?.answerNo === 0 || pages?.[7]?.answerNo === 14) {
      //   setCardsOptionPreselectLog(
      //     title,
      //     3,
      //     'pages?.[6]?.answerNo === 3 && (pages?.[7]?.answerNo === 14',
      //     userId,
      //     log,
      //   )
      //   return 14
      // }
      if (pages?.[7]?.answerNo === 1 || pages?.[7]?.answerNo === 15) {
        // if (consumptions > consumptionThreshold) {
        //   setCardsOptionPreselectLog(
        //     title,
        //     15,
        //     `pages?.[6]?.answerNo === 3 && (pages?.[7]?.answerNo === 15 && consumptions > ${consumptionThreshold}`,
        //     userId,
        //     log,
        //   )
        //   return 15
        // }
        if (pages?.[28]?.answerNo === 0) {
          setCardsOptionPreselectLog(
            title,
            6,
            `pages?.[6]?.answerNo === 3 && (pages?.[7]?.answerNo === 15 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
            userId,
            log,
          )
          return 6
        }
        setCardsOptionPreselectLog(
          title,
          9,
          `pages?.[6]?.answerNo === 3 && (pages?.[7]?.answerNo === 15 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
          userId,
          log,
        )

        return 9
      }
      if (pages?.[7]?.answerNo === 2 || pages?.[7]?.answerNo === 16) {
        // if (consumptions > consumptionThreshold) {
        //   setCardsOptionPreselectLog(
        //     title,
        //     16,
        //     `pages?.[6]?.answerNo === 3 && (pages?.[7]?.answerNo === 16 && consumptions > ${consumptionThreshold}`,
        //     userId,
        //     log,
        //   )

        //   return 16
        // }
        if (pages?.[28]?.answerNo === 0) {
          setCardsOptionPreselectLog(
            title,
            6,
            `pages?.[6]?.answerNo === 3 && (pages?.[7]?.answerNo === 16 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo === 0`,
            userId,
            log,
          )

          return 6
        }
        setCardsOptionPreselectLog(
          title,
          9,
          `pages?.[6]?.answerNo === 3 && (pages?.[7]?.answerNo === 16 && consumptions < ${consumptionThreshold} && pages?.[28]?.answerNo != 0`,
          userId,
          log,
        )

        return 9
      }
      setCardsOptionPreselectLog(
        title,
        3,
        'pages?.[6]?.answerNo === 3 && pages?.[7]?.answerNo != 14 && pages?.[7]?.answerNo != 15 && pages?.[7]?.answerNo != 16',
        userId,
        log,
      )

      return 16
    }
  }
  if (title === 'Ventilatie') {
    if (pages?.[21]?.answerNo === 3) {
      setCardsOptionPreselectLog(title, 4, 'Default', userId, log)
      return 4
    }
    setCardsOptionPreselectLog(title, 3, 'Default', userId, log)
    return 3
  }
  if (title === 'Warm water') {
    setCardsOptionPreselectLog(title, 2, 'Default', userId, log)
    return 2
  }
  if (title === 'Zonnepanelen') {
    setCardsOptionPreselectLog(title, 1, 'Default', userId, log)
    return 1
  }
  if (title === 'Groendak') {
    setCardsOptionPreselectLog(title, 0, 'Default', userId, log)
    return 0
  }
  if (title === 'Regenput') {
    setCardsOptionPreselectLog(title, 0, 'Default', userId, log)
    return 0
  }
  if (pageIndex == 30) {
    setCardsOptionPreselectLog(title, 1, 'Default', userId, log)
    return 1
  }
  if (title === 'Zonnewering') {
    setCardsOptionPreselectLog(title, 2, 'Default', userId, log)
    return 2
  }
  return false
}

export function getIncreaseDecreaseLabel(title,pageIndex) {
  if (title === 'Hoofddak') {
    return 'Hoofddak: Oppervlakte dak in m²'
  }
  if (title === 'Bijdak') {
    return 'Bijdak: Oppervlakte dak in m²'
  }
  if (title === 'Ramen leefruimte') {
    return 'Ramen leefruimte: Oppervlakte ramen leefruimte in m²'
  }
  if (title === 'Ramen slaapkamers') {
    return 'Ramen slaapkamer: Oppervlakte ramen slaapkamer in m²'
  }
  if (title === 'Voorgevel') {
    return 'Voorgevel: Oppervlakte voorgevel in m²'
  }
  if (title === 'Zonnewering') {
    return 'Zonnewering: Oppervlakte zonneschermen in m²'
  }
  if (title === 'Groendak') {
    return 'Groendak: Oppervlakte groendak in m²'
  }
  if (title === 'Achter- en zijgevels') {
    return 'Achter- en zijgevels: Oppervlakte achter- en zijgevels in m²'
  }
  if (title === 'Vloerisolatie') {
    return 'Vloerisolatie: Bewoonbare oppervlakte in m²'
  }
  if (title === 'Zonnepanelen') {
    return 'Zonnepanelen: Oppervlakte dak in m²'
  }
  if(pageIndex == 29){
    return 'Grondoppervlakte (hoofd- en/of bij-)gebouw'
  }
  return false
}

export function getBooleanMInfo(title) {
  if (title === 'Hoofddak') {
    return true
  }
  if (title === 'Bijdak') {
    return true
  }
  if (title === 'Ramen leefruimte') {
    return true
  }
  if (title === 'Ramen slaapkamers') {
    return true
  }
  if (title === 'Achter- en zijgevels') {
    return true
  }
  if (title === 'Ventilatie') {
    return true
  }
  if (title === 'Warm water') {
    return true
  }
  if (title === 'Zonnepanelen') {
    return true
  }
  if (title === 'Groendak') {
    return true
  }
  if (title === 'Zonnewering') {
    return true
  }
  if (title === 'Regenput') {
    return true
  }
  if (title === 'Vergroenen') {
    return true
  }
  if (title === 'Voorgevel') {
    return true
  }
  if (title === 'Vloerisolatie') {
    return true
  }
  if (title === 'Verwarming') {
    return true
  }
  return false
}

export function modalContent(status, title, answerNo, pageSixAnswerKey) {
  if (status === 'green') {
    if (title === 'Verwarming') {
      return `AdviesS_${title}_${pageSixAnswerKey}_${answerNo}_groen`
    }
    return `AdviesS_${title}_${answerNo}_groen`
  }
  if (status === 'orange') {
    if (title === 'Verwarming') {
      return `AdviesS_${title}_${pageSixAnswerKey}_${answerNo}_oranje`
    }
    return `AdviesS_${title}_${answerNo}_oranje`
  }
  if (status === 'red') {
    if (title === 'Verwarming') {
      return `AdviesS_${title}_${pageSixAnswerKey}_${answerNo}_rood`
    }
    return `AdviesS_${title}_${answerNo}_rood`
  }
  return `AdviesS_${title}_${answerNo}`
}

export function modalMoreInfo(status, title, answerNo, pageSixAnswerKey) {
  if (status === 'green') {
    if (title === 'Verwarming') {
      return `AdviesXL_${title}_${pageSixAnswerKey}_${answerNo}_groen`
    }
    return `AdviesXL_${title}_${answerNo}_groen`
  }
  if (status === 'orange') {
    if (title === 'Verwarming') {
      return `AdviesXL_${title}_${pageSixAnswerKey}_${answerNo}_oranje`
    }
    return `AdviesXL_${title}_${answerNo}_oranje`
  }
  if (status === 'red') {
    if (title === 'Verwarming') {
      return `AdviesXL_${title}_${pageSixAnswerKey}_${answerNo}_rood`
    }
    return `AdviesXL_${title}_${answerNo}_rood`
  }
  return `AdviesXL_${title}_${answerNo}`
}

export function getOppervlakteKey(pages, buildingData = {}) {
  if (
    (pages?.[1]?.answerNo === 0 &&
      pages?.[3]?.answerNo === 0 &&
      !pages?.[3]?.oppervlakte_input) ||
    (pages?.[1]?.answerNo === 0 &&
      pages?.[3]?.oppervlakte_input >= buildingData.groot_open)
  ) {
    return 'groot_open'
  }
  if (
    (pages?.[1]?.answerNo === 0 &&
      pages?.[3]?.answerNo === 1 &&
      !pages?.[3]?.oppervlakte_input) ||
    (pages?.[1]?.answerNo === 0 &&
      pages?.[3]?.oppervlakte_input < buildingData.groot_open &&
      pages?.[3]?.oppervlakte_input > buildingData.klein_open)
  ) {
    return 'middel_open'
  }
  if (
    (pages?.[1]?.answerNo === 0 &&
      pages?.[3]?.answerNo === 2 &&
      !pages?.[3]?.oppervlakte_input) ||
    (pages?.[1]?.answerNo === 0 &&
      pages?.[3]?.oppervlakte_input <= buildingData.klein_open)
  ) {
    return 'klein_open'
  }
  if (
    (pages?.[1]?.answerNo === 1 &&
      pages?.[3]?.answerNo === 0 &&
      !pages?.[3]?.oppervlakte_input) ||
    (pages?.[1]?.answerNo === 1 &&
      pages?.[3]?.oppervlakte_input >= buildingData.groot_halfopen)
  ) {
    return 'groot_halfopen'
  }
  if (
    (pages?.[1]?.answerNo === 1 &&
      pages?.[3]?.answerNo === 1 &&
      !pages?.[3]?.oppervlakte_input) ||
    (pages?.[1]?.answerNo === 1 &&
      pages?.[3]?.oppervlakte_input < buildingData.groot_halfopen &&
      pages?.[3]?.oppervlakte_input > buildingData.klein_halfopen)
  ) {
    return 'middel_halfopen'
  }
  if (
    (pages?.[1]?.answerNo === 1 &&
      pages?.[3]?.answerNo === 2 &&
      !pages?.[3]?.oppervlakte_input) ||
    (pages?.[1]?.answerNo === 1 &&
      pages?.[3]?.oppervlakte_input <= buildingData.klein_halfopen)
  ) {
    return 'klein_halfopen'
  }
  if (
    (pages?.[1]?.answerNo === 2 &&
      pages?.[3]?.answerNo === 0 &&
      !pages?.[3]?.oppervlakte_input) ||
    (pages?.[1]?.answerNo === 2 &&
      pages?.[3]?.oppervlakte_input >= buildingData.groot_gesloten)
  ) {
    return 'groot_gesloten'
  }
  if (
    (pages?.[1]?.answerNo === 2 &&
      pages?.[3]?.answerNo === 1 &&
      !pages?.[3]?.oppervlakte_input) ||
    (pages?.[1]?.answerNo === 2 &&
      pages?.[3]?.oppervlakte_input < buildingData.groot_gesloten &&
      pages?.[3]?.oppervlakte_input > buildingData.klein_gesloten)
  ) {
    return 'middel_gesloten'
  }
  if (
    (pages?.[1]?.answerNo === 2 &&
      pages?.[3]?.answerNo === 2 &&
      !pages?.[3]?.oppervlakte_input) ||
    (pages?.[1]?.answerNo === 2 &&
      pages?.[3]?.oppervlakte_input <= buildingData.klein_gesloten)
  ) {
    return 'klein_gesloten'
  }
  return ''
}

export function getOppervlakteId(modalTitle,pageIndex) {
  //regenput
  if(pageIndex == 29){
    return { key1: 2, key2: 3 }
  }
  if (modalTitle === 'Vloerisolatie') {
    return 2
  }
  if (modalTitle === 'Voorgevel') {
    return 4
  }
  if (modalTitle === 'Achter- en zijgevels') {
    return 5
  }
  if (modalTitle === 'Ramen leefruimte') {
    return 7
  }
  if (modalTitle === 'Ramen slaapkamers') {
    return 8
  }
  if (modalTitle === 'Zonnewering') {
    return { key1: 7, key2: 8 }
  }
  if (modalTitle === 'Groendak') {
    return { key1: 9, key2: 10 }
  }
  if (modalTitle === 'Hoofddak') {
    return { key1: 9, key2: 10, key3: 9 }
  }
  if (modalTitle === 'Bijdak') {
    return 10
  }
  return 1
}

export function getObjValuesSum(values) {
  return Math.round(
    Object.values(values)
      .reduce((val, current) => val + Number(current), 0)
      .toFixed(2),
  )
}

export async function getOverviewValues(
  localState,
  localImprovements,
  setMinderEnergieValue,
  setCO2Value,
  setBespaarValue,
  setElectricityValue,
  setGasValue,
  userId,
  values,
  dispatch,
) {

  if(localState && localImprovements){
    const parameters = JSON.parse(localStorage.getItem('parameters'))
    const params = parameters?.data?.data?.[0] || []
    const gasParam =
      params.find((param) => param?.name === 'CO2/kWh voor gas')?.value || 0
    const electParam =
      params.find((param) => param?.name === 'CO2/kWh voor elektriciteit')
        ?.value || 0
    const unitRateGas = parseRate(parameters, 0)
    const unitRateElec = parseRate(parameters, 1)
    const unitRateInjectie = parseRate(parameters, 2)
  
    // console.log("localState",localState)
    // console.log("localImprovements",localImprovements)
    const payload = await getCalculationPayload(localState)
    const improvedPayload = await getCalculationPayload(
      localImprovements,
      true,
      0,
      1,
    )
    // console.log("A> localImprovements",localImprovements)
    // console.log("A> improvedPayload",improvedPayload)
    // console.log("alain",localImprovements[22])
    // console.log("alain",localImprovements[23])
    // console.log("localImprovements[25]",localImprovements[25])
  
    let energyDifferencePanel = 0
    let energyRatePanel = 0
    let verkoopElecPanel = 0
    const verbruikZonnepaneelKwh =
      parameters?.data?.data[0].find((item) => item.id === '35') || 0
    const zelfconsumptieZonnepanelen =
      parameters?.data?.data[0].find((item) => item.id === '33') || 0
    const area = Number(localImprovements[23].aantal_zonnepanelen)
  
    if (
      localImprovements[23].aantal_zonnepanelen > 0 &&
      localState[23].aantal_zonnepanelen == ''
    ) {
      // console.log("getOverviewValues - calculation zonnepannel start")
  
      // await axios
      //   .get(`${APP_URL}/dimensions?getDimensionsZonnepannelen`)
      //   .then((response) => {
      //     const data = response.data.filter((d) => Number(d.id) === 1)
      //     const buildingData=response.data
  
      //     const opperKey = getOppervlakteKey(localImprovements, buildingData && buildingData[0])
  
      //     // const area = Math.round(data[0][opperKey]);
  
      if (localImprovements[25].answerNo == 0) {
        energyDifferencePanel =
          (area *
            verbruikZonnepaneelKwh.value *
            zelfconsumptieZonnepanelen.value) /
          100
        energyRatePanel =
          (area *
            verbruikZonnepaneelKwh.value *
            zelfconsumptieZonnepanelen.value *
            unitRateElec +
            area *
              verbruikZonnepaneelKwh.value *
              (100 - zelfconsumptieZonnepanelen.value) *
              unitRateInjectie) /
          100
        verkoopElecPanel =
          area *
          verbruikZonnepaneelKwh.value *
          ((100 - zelfconsumptieZonnepanelen.value) / 100)
      }
      if (localImprovements[25].answerNo == 1) {
        energyDifferencePanel = area * verbruikZonnepaneelKwh.value
        energyRatePanel =
          area * Number(verbruikZonnepaneelKwh.value) * Number(unitRateElec)
        verkoopElecPanel =
          area *
          verbruikZonnepaneelKwh.value *
          (100 - zelfconsumptieZonnepanelen.value)
      }
      // })
      // .catch((err) => {
      //   console.log("error getDimensionsZonnepannelen",err)
      //     return 0
      // })
    }
  
    // console.log("getOverviewValues energyDifferencePanel",energyDifferencePanel)
    // console.log('getOverviewValues energyRatePanel', energyRatePanel)
    // console.log("getOverviewValues verkoopElecPanel",verkoopElecPanel)
    // console.log("getOverviewValues area",area)
    // console.log("getOverviewValues verbruikZonnepaneelKwh",verbruikZonnepaneelKwh.value)
    // console.log("getOverviewValues energyDifferencePanel",zelfconsumptieZonnepanelen.value)
    // console.log("getOverviewValues energyRatePanel",energyRatePanel)
    // console.log("getOverviewValues energyRatePanel",verkoopElecPanel)
  
    const storedResult = localStorage.getItem('result')
    const result = JSON.parse(storedResult)
    const storedCurrentPayload = localStorage.getItem('currentPayload')
    const storeImprovedPayload = localStorage.getItem('improvedPayload')
    const checkCurrentPayload = JSON.stringify(payload) === storedCurrentPayload
  
    const checkImprovedPayload =
      JSON.stringify(improvedPayload) === storeImprovedPayload
  
    if (checkCurrentPayload && checkImprovedPayload && storedResult) {
      const { newMeasures } = result.data
  
      localStorage.setItem(
        'energyGasNew',
        newMeasures.adjustedScaledGas + newMeasures.adjustedScaledElec,
      )
      const gas = result.data.estimatedSavings?.gas
      const elec = result.data.estimatedSavings?.elec
      const co2 =
        Number(
          gas * gasParam + (elec + energyDifferencePanel) * electParam,
        ).toFixed(2) > 0
          ? Number(
              gas * gasParam + (elec + energyDifferencePanel) * electParam,
            ).toFixed(2)
          : 0
      const difference =
        Number(gas + elec).toFixed(2) > 0 ? Number(gas + elec).toFixed(2) : 0
      const energyRate = Number(
        Number(gas * unitRateGas) + Number(elec * unitRateElec),
      ).toFixed(2)
      setCO2Value(Number(co2).toFixed(2))
      setMinderEnergieValue(
        (Number(difference) + energyDifferencePanel).toFixed(2),
      )
      setBespaarValue((Number(energyRate) + energyRatePanel).toFixed(2))
      setElectricityValue((Number(elec) + energyDifferencePanel).toFixed(2))
      setGasValue(Number(gas).toFixed(2))
    } else {
      axios
        .post(`${APP_URL}/calculation?getOverviewValues`, {
          current: payload,
          improvements: improvedPayload,
          userId,
        })
        .then(async (response) => {
          const { newMeasures } = response.data
  
          localStorage.setItem(
            'energyGasNew',
            newMeasures.adjustedScaledGas + newMeasures.adjustedScaledElec,
          )
          const gas = response.data.estimatedSavings?.gas
          const elec = response.data.estimatedSavings?.elec
          const co2 =
            Number(
              gas * gasParam + (elec + energyDifferencePanel) * electParam,
            ).toFixed(2) > 0
              ? Number(
                  gas * gasParam + (elec + energyDifferencePanel) * electParam,
                ).toFixed(2)
              : 0
          const difference =
            Number(gas + elec).toFixed(2) > 0 ? Number(gas + elec).toFixed(2) : 0
          const energyRate = Number(
            Number(gas * unitRateGas) + Number(elec * unitRateElec),
          ).toFixed(2)
          setCO2Value(Number(co2).toFixed(2))
          setMinderEnergieValue(
            (Number(difference) + energyDifferencePanel).toFixed(2),
          )
          setBespaarValue((Number(energyRate) + energyRatePanel).toFixed(2))
          setElectricityValue((Number(elec) + energyDifferencePanel).toFixed(2))
          setGasValue(Number(gas).toFixed(2))
        })
        .catch((exception) => console.log(exception))
    }
  }
 
}

export function getAnswer(pages, key) {
  return pages?.[key].answerNo
}

export function getSelectedModalTitle(modalTitle, pages) {
  if (modalTitle === 'Verwarming') {
    if (pages[6].verwarming === 'Een andere bron') {
      return `${modalTitle}: Anderebron`
    }
    return `${modalTitle}: ${pages[6].verwarming}`
  }
  return modalTitle
}

export function getVerwarmingTableData(data, modalTitle, selectedOptText) {
  let tableData = []
  if (
    modalTitle === 'Verwarming: Gas' ||
    modalTitle === 'Verwarming: Elektriciteit'
  ) {
    data.map((table) => {
      if (
        table.improvementName === 'Verwarming: Gas' ||
        table.improvementName === 'Verwarming: Elektriciteit' ||
        table.improvementName === 'Verwarming: Anderebron'
      ) {
        tableData.push(table)
      }
    })
  }
  if (modalTitle === 'Verwarming: Stookolie') {
    data.map((table) => {
      if (
        table.improvementName === 'Verwarming: Elektriciteit' ||
        table.improvementName === 'Verwarming: Stookolie'
      ) {
        tableData.push(table)
      }
    })
  }
  if (modalTitle === 'Verwarming: Anderebron') {
    data.map((table) => {
      if (
        table.improvementName === 'Verwarming: Elektriciteit' ||
        table.improvementName === 'Verwarming: Anderebron'
      ) {
        tableData.push(table)
      }
    })
  }
  if (tableData.length > 0) {
    tableData = tableData
      .filter((table) =>
        table.improvementOptions.some(
          (imp) => imp.selectedOption === selectedOptText,
        ),
      )
      .map((table) => {
        return {
          ...table,
          improvementOptions: table.improvementOptions.filter(
            (imp) => imp.selectedOption === selectedOptText,
          ),
        }
      })
  }
  return tableData
}

export function getOptionFun(values, dropDownOptions, answerKey) {
  if (dropDownOptions) {
    if (Object.keys(dropDownOptions[0]).length > 1) {
      const optVal = Number(values[answerKey])
      const rVal = Object.keys(dropDownOptions[0]).filter(
        (dp) => Number(dropDownOptions[0][dp]) === optVal,
      )
      return rVal[0]
    }
    return Object.keys(dropDownOptions[0])[0]
  }
  return ''
}

export function getSelectedOptFun(dropDownOptions, selectedOption) {
  const optVal = Number(selectedOption)
  if (dropDownOptions) {
    const rVal = Object.keys(dropDownOptions[0]).filter(
      (dp) => Number(dropDownOptions[0][dp]) === optVal,
    )
    return rVal[0]
  }
  return ''
}

export function getPremImprovment(modalTitle, pageIndex, selectedOptText, pages) {
  if (modalTitle === 'Verwarming') {
    if (pages[6].verwarming === 'Een andere bron') {
      return `${modalTitle} - Andere bron - ${selectedOptText}`
    }
    if (
      selectedOptText === 'Hybride warmtepomp' ||
      selectedOptText === 'Warmtepomp lucht/lucht' ||
      selectedOptText === 'Warmtepomp lucht/water' ||
      selectedOptText === 'Warmtepomp water/water' ||
      selectedOptText === 'Warmtepomp bodem/water'
    ) {
      return `${modalTitle} - Elektriciteit - ${selectedOptText}`
    }
    if (selectedOptText === 'Warmtenet') {
      return `${modalTitle} - Andere bron - ${selectedOptText}`
    }
    return `${modalTitle} - ${pages[6].verwarming} - ${selectedOptText}`
  }
  if (modalTitle === 'Zonnepanelen') {
    return `${modalTitle}`
  }
  if (modalTitle === 'Groendak') {
    return `${modalTitle}`
  }
  if (modalTitle === 'Regenput') {
    return `${modalTitle}`
  }
  if (pageIndex == 30) {
    return `${modalTitle}`
  }
  return `${modalTitle} - ${selectedOptText}`
}

export function getAdviceKey(status, title, answerNo, selectedOption) {
  const titleNoSpace = title?.replace(/\s+/g, '_')
  const ansNo = title === 'Verwarming' ? selectedOption : answerNo + 1
  if (status === 'green' || status === 'midgreen') {
    return `${titleNoSpace}_${ansNo}_advice_Green`
  }
  if (status === 'orange') {
    return `${titleNoSpace}_${ansNo}_advice_Orange`
  }
  if (status === 'red') {
    return `${titleNoSpace}_${ansNo}_advice_Red`
  }
  return ''
}

export function getAdviceKeyXL(status, title, answerNo, selectedOption) {
  const titleNoSpace = title?.replace(/\s+/g, '_')
  const ansNo = title === 'Verwarming' ? selectedOption : answerNo + 1
  if (status === 'green' || status === 'midgreen') {
    return `${titleNoSpace}_${ansNo}_advice_GreenXL`
  }
  if (status === 'orange') {
    return `${titleNoSpace}_${ansNo}_advice_OrangeXL`
  }
  if (status === 'red') {
    return `${titleNoSpace}_${ansNo}_advice_RedXL`
  }
  return ''
}

export function isEmptyObj(obj = {}) {
  return !Object.keys(obj).length
}

export function appendRoute(pageNo, page6AnswerNumber, propTitle) {
  let title = propTitle || ''
  if (pageNo === 7) {
    title = getQuestionKey(pageNo, page6AnswerNumber).replace(/\_/g, '')
  } else {
    title = pageTitles[`questionnaire${pageNo}`]
      .split('|')[0]
      .replace(/\ /g, '')
      .toLowerCase()
  }
  return `/vraag/${pageNo}/${title}`
}

export const getEnergyTitle = (answerSix, answerSeven) => {
  switch (answerSix) {
    case 0:
      return 'gas'
    case 2:
      return 'stookolie'
    case 3:
      switch (answerSeven) {
        case 1:
          return 'pellets'
        case 2:
          return 'hout'
        default:
          return ''
      }
    default:
      return ''
  }
}

export const getImage = (answerNo) => {
  switch (answerNo) {
    case 0:
      return 'step6Image1'
    case 1:
      return 'step6Image2'
    case 2:
      return 'step6Image3'
    default:
      return 'step6Image4'
  }
}