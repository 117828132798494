import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/footer'
import Header from '../../components/header'

import './error.scss'

const PageNotFound = ({ notFoundLabelData, footerLabelData }) => {
  const h1Ref = useRef(null)
  const { introduction, title, linkText } = notFoundLabelData
  useEffect(() => {
    if (h1Ref?.current) {
      h1Ref.current.focus()
    }
  }, [])

  return (
    <>
      <a className="skiplink" href="#main">
        Skip to content
      </a>
      <div className="container error-container">
        <Header />
        <main id="main" className="detail-layout">
          <div className="error-inner">
            <h1
              tabIndex="-1"
              ref={h1Ref}
              dangerouslySetInnerHTML={{ __html: title }}
            />

            <h4 dangerouslySetInnerHTML={{ __html: introduction }} />
            <Link
              to="/"
              className="standalone-link back"
              dangerouslySetInnerHTML={{ __html: linkText }}
            />
          </div>
        </main>
      </div>
      <Footer footer={footerLabelData} />
    </>
  )
}
export default PageNotFound
