import Cookies from 'js-cookie'
import { getCookieMeta } from '../../utils/helpers'

export const SET_PAGE = 'SET_PAGE'
export const SET_LOADING = 'SET_LOADING'
export const SET_STATE_FROM_COOKIES = 'SET_STATE_FROM_COOKIES'
export const SET_DEFAULT_STATE = 'SET_DEFAULT_STATE'

export function setLoading(isLoading) {
  return {
    type: SET_LOADING,
    payload: isLoading,
  }
}

export function setStateFromCookies(pages) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_STATE_FROM_COOKIES,
      payload: { ...getState().questionnaire, pages },
    })
  }
}
export function setDefaultState(pages) {
  return (dispatch) => {
    dispatch({
      type: SET_DEFAULT_STATE,
      payload: pages,
    })
  }
}

export function setPage(page, setCookies) {
  return (dispatch, getState) => {
    const {
      questionnaire: { pages },
    } = getState()

    const updatedPages =
      page.pageNo == 22 && page.data.answerNo === 1
        ? {
            ...pages,
            [page.pageNo]: {
              ...pages[page.pageNo],
              ...page.data,
            },
            23: { aantal_zonnepanelen: '' },
          }
        : {
            ...pages,
            [page.pageNo]: {
              ...pages[page.pageNo],
              ...page.data,
            },
          }
    if (page.pageNo == 26) {
      localStorage.setItem(
        'elektriciteitsverbruik',
        page.data.elektriciteitsverbruik,
      )
      localStorage.setItem('aardgasverbruik', page.data.aardgasverbruik)
      localStorage.setItem('zonnepanelen', page.data.zonnepanelen)
      localStorage.setItem('energieproductie', page.data.energieproductie)
    }
    dispatch(setLoading(true))
    setCookies('localState', JSON.stringify(updatedPages), getCookieMeta())
    dispatch({
      type: SET_PAGE,
      payload: updatedPages,
    })
    if (page.pageNo != 25) setTimeout(() => dispatch(setLoading(false)), 500)
  }
}
