import React, { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import FocusTrap from 'focus-trap-react'
import { useSelector } from 'react-redux'
import HeatMap from './VerwarmingHeatMap'

import './heatModal.scss'

const VerwarmingHeatMapModal = (props) => {
  const { show, handleHideModal } = props
  const { modal } = useSelector((state) => state.common)
  const modalRef = useRef(null)

  useEffect(() => {
    if (show) {
      modalRef.current.focus()
      document.getElementById('root').setAttribute('aria-hidden', false)
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    }

    return () => {
      modalRef.current.focus()
      document.getElementById('root').setAttribute('aria-hidden', false)
      document.getElementsByTagName('body')[0].style.overflow = 'initial'
    }
  }, [show])

  const children = (
    <div
      ref={modalRef}
      id="heat-map-modal"
      className={
        show ? 'modal modal--fixed-height visible' : 'modal modal--fixed-height'
      }
      role="dialog"
      aria-modal="true"
      aria-labelledby="heat-map-modal-title"
      tabIndex="-1"
    >
      <div className="modal-inner map-modal_resp">
        <div className="modal-header">
          <button
            type="button"
            className="button close icon-cross modal-close"
            data-target="heat-map-modal"
            onClick={handleHideModal}
            aria-expanded="false"
          >
            <span>Sluiten</span>
          </button>
        </div>

        <div className="modal-content heat-loss-modal-content">
          <h3
            id="heat-map-modal-title"
            dangerouslySetInnerHTML={{
              __html: modal.verwarming_map_modal_title,
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: modal.verwarming_map_modal_description,
            }}
          />
          <HeatMap />
          <div className="map-legends">
            <p
              className="map-text"
              dangerouslySetInnerHTML={{
                __html: modal.verwarming_map_modal_footer,
              }}
            />
            <span className="dot left green-heat"> </span>{' '}
            <span className="left text">Warmtenet</span>
            <span className="dot left red-heat"> </span>{' '}
            <span className="left text">Warmtepomp lucht of bodem/water</span>
            <span className="dot left orange-heat"> </span>{' '}
            <span className="left text">Warmtepomp lucht/lucht of water</span>
            <span className="dot left yellow-heat"> </span>{' '}
            <span className="left text">Warmtepomp alle types</span>
          </div>
        </div>
        <div className="modal-actions">
          <button
            type="button"
            className="button button-primary modal-close"
            data-target="modal-meerInfo"
            onClick={handleHideModal}
          >
            Terug naar het advies
          </button>
        </div>
      </div>
      <div
        className="modal-overlay modal-close"
        data-target="heat-map-modal"
        tabIndex="-1"
        onMouseDown={handleHideModal}
        role="button"
        aria-label="modal"
      />
    </div>
  )

  return createPortal(
    show ? <FocusTrap>{children}</FocusTrap> : children,
    document.getElementsByTagName('body')[0],
  )
}
export default VerwarmingHeatMapModal
