import React from 'react'
import MoreInfoButton from '../../../components/moreInfoButton'
import { Images } from '../../../utils/constants'

const CommonLeftSection = (props) => {
  const {
    title,
    introduction,
    metaTitle,
    modalData,
    answerSetKey,
    leftSectionImage,
    progressValue,
    imageTitle,
  } = props

  return (
    <>
      <h1 className="visually-hidden">{metaTitle}</h1>
      <h2 tabIndex="-1" dangerouslySetInnerHTML={{ __html: title }} />
      {introduction && (
        <p
          className="introduction"
          dangerouslySetInnerHTML={{ __html: introduction }}
        />
      )}
      <MoreInfoButton
        desktop
        modalData={modalData}
        answerSetKey={answerSetKey}
      />
      {leftSectionImage && (
        <>
          <figure className="questionnaire-camera-image">
            {/* <progress value={progressValue} max="100" />
            <p className="left-section-image-title">{imageTitle}</p> */}
            <div className="left-section-image">{Images[leftSectionImage]}</div>
          </figure>
        </>
      )}
    </>
  )
}
export default CommonLeftSection
