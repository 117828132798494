import React, { useMemo } from 'react'
import Cookies from 'js-cookie'

import { classNames, getEnergyTitle, getQuestionKey } from '../../utils/helpers'

export default function ModalContent(props) {
  const {
    status = '',
    title = '',
    subtitle = '',
    pageIndex,
    cookies,
    calculations,
    labelData,
    page25,
    optionNo,
    pages,
  } = props
  const {
    investeer,
    premies,
    bespaar,
    minderEnergie,
    area,
    verkoopElectricity,
  } = calculations
  const pagesEnergyGas = localStorage.getItem('pagesEnergyGas')
  const pagesEnergyElec = localStorage.getItem('pagesEnergyElec')
  const page6 = pages?.[6] && pages?.[6].answerNo
  const page7 = pages?.[7] && pages?.[7].answerNo
  const key = useMemo(
    () =>
      Cookies.get("localImprovements")
        ? getQuestionKey(pageIndex, JSON.parse(Cookies.get("localImprovements"))[6]?.answerNo)
        : '',
    [pageIndex, Cookies.get("localImprovements")],
  )
  const labelKey = useMemo(() => {
    return `pdf_${title
      .toLowerCase()
      .replace(' ', '_')
      .replace(' ', '_')}_label`
  }, [title])
  const statusClasses = classNames({
    status: true,
    green: status === 'green' || status === 'midgreen',
    orange: status === 'orange',
    red: status === 'red',
  })

  return (
    <article className="modal-content">
      <div className="title">
        <h3>{title}</h3>
        <p>
          <span className={statusClasses} />
          <span className="sub-title">{subtitle}</span>
        </p>
        {pageIndex == 30 &&
        <>
          {Cookies.get("vergronenArea1") > 0 && <p><strong>Oppervlakte te te ontharden in voortuin : {Cookies.get("vergronenArea1")} m²</strong></p>}
          {Cookies.get("vergronenArea2") > 0 && <p><strong>Oppervlakte te te ontharden in achtertuin : {Cookies.get("vergronenArea2")} m²</strong></p>}
          {Cookies.get("vergronenArea3") > 0 && <p><strong>Oppervlakte te te ontharden op oprit : {Cookies.get("vergronenArea3")} m²</strong></p>}
        </>
        

        }
        {pageIndex == 29 &&
        <>
          {area.regenwaterput > 0 && <p><strong>Voor een grondoppervlakte (hoofd- en/of bij-)gebouw : {area.regenwaterput} m²</strong></p>}
        </>
        

        }
       

        {area[key] &&
          ![
            'Ventilatie',
            'Verwarming',
            'Warm water',
            'Regenput',
            'Vergroenen',
            'Zonnepanelen',
          ].includes(title) && (
            <p>
              <strong>
                {labelData[labelKey]}: {Math.round(area[key])} m²
              </strong>
            </p>
          )}
      </div>
      {/* {title !== 'Vergroenen' && ( */}
        <div className="calculations">
          <ul className="over-saving-list">
            <li>
              <p>Ik investeer</p>
              <p>
                €{' '}
                {Number.isNaN(Math.round(investeer[key]))
                  ? 0
                  : Math.round(investeer[key])}
              </p>
            </li>
            <li>
              <p>Ik ontvang premies</p>
              <p>
                €{' '}
                {Number.isNaN(Math.round(premies[key]))
                  ? 0
                  : Math.round(premies[key])}
              </p>
            </li>
            {title === 'Zonnepanelen' ? (
              <>
                <li>
                  <p> Ik verbruik minder energie</p>
                  <p>{Math.round(minderEnergie[key])} kWh / jaar</p>
                </li>
                <li>
                  <p>Ik verkoop elektriciteit</p>
                  <p>{Math.round(verkoopElectricity[key])} kWh / jaar</p>
                </li>
              </>
            ) : title === 'Verwarming' &&
              page6 !== 1 &&
              (optionNo == 6 ||
                optionNo == 7 ||
                optionNo == 8 ||
                optionNo == 9 ||
                optionNo == 5) ? (
              <>
                <li>
                  <p>Ik verbruik minder elektriciteit</p>
                  <p>{Math.round(pagesEnergyElec)} kWh / jaar</p>
                </li>
                <li>
                  <p>Ik verbruik minder {getEnergyTitle(page6, page7)}</p>
                  <p>{Math.round(pagesEnergyGas)} kWh / jaar</p>
                </li>
              </>
            ) : pageIndex != 30 && pageIndex != 29 && (
              <li>
                <p>Ik verbruik minder energie</p>
                <p>
                  {Number.isNaN(Math.round(minderEnergie[key]))
                    ? 0
                    : Math.round(minderEnergie[key])}{' '}
                  kWh / jaar
                </p>
              </li>
            )}
            {/* <li>
              <p>Ik verbruik minder energie</p>
              <p>{Math.round(minderEnergie[key])} kWh / jaar</p>
            </li> */}
            {pageIndex != 30 && pageIndex !=29 && 
            <li>
              <p>
                <strong>Ik bespaar</strong>
              </p>
              <p>
                <strong>
                  €{' '}
                  {Number.isNaN(Math.round(bespaar[key]))
                    ? 0
                    : Math.round(bespaar[key])}{' '}
                  / jaar
                </strong>
              </p>
            </li>
            }
          </ul>
        </div>
      {/* )} */}
    </article>
  )
}
