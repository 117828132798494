import React, { useEffect, useMemo, useState } from 'react'
import { getAdviceText, getCalculationOfPage6 } from '../../utils/apiActions'
import { isEmptyObj } from '../../utils/helpers'
import Cookies from 'js-cookie'

export default function AdviceContent(props) {
  const { status, title, cookies, pageIndex } = props
  const [state, setState] = useState({})
  const improvedSelection = useMemo(
    () =>
      !isEmptyObj(Cookies.get("localImprovements"))
        ? getCalculationOfPage6(
            JSON.parse(Cookies.get("localImprovements")),
            JSON.parse(Cookies.get("localImprovements"))['7']?.fromImprovement,
          )
        : {},
    [Cookies.get("localImprovements")],
  )
  useEffect(() => {
    const cookiesData = Cookies.get("localImprovements");
    const parsedData = cookiesData ? JSON.parse(cookiesData) : null;  
    if(Cookies.get("localImprovements"))
      getAdviceText(
        setState,
        status,
        title,
        pageIndex,
        JSON.parse(Cookies.get("localImprovements"))[pageIndex]?.answerNo,
        improvedSelection,
      )
  }, [status, Cookies.get("localImprovements")])

  return (
    <article>
      <h5>Meer info bij ons advies</h5>
      <p dangerouslySetInnerHTML={{ __html: state.adviceContentXL }} />
    </article>
  )
}
