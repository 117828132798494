import { combineReducers } from 'redux'

import questionnaire from './questionnaire'
import result from './result'
import common from './common'

export default combineReducers({
  questionnaire,
  result,
  common,
})
