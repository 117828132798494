import { SET_IS_LAODING, SET_TEXT } from '../actions'

const initialState = {
  home: {},
  result: {},
  questionnaire: {},
  advice: {},
  not_found: {},
  modal_meer_info: {},
  pdf: {},
  modal: {},
  isLoading: true,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TEXT:
      return { ...state, ...action.payload }
    case SET_IS_LAODING:
      return {
        ...state,
        isLoading: action.payload,
      }
    default:
      return state
  }
}
