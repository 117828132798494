import React from 'react'

import './styles.scss'

const AdviceCard = ({ adviceCard = {}, dynamicClass }) => {
  const {
    title = '',
    introduction = '',
    buttonText = '',
    buttonLink = '',
    linkLink = '',
    linkText = '',
  } = adviceCard
  return (
    <section className="highlight cta-block link highlight--left advice-card">
      <div className="highlight__inner">
        <h2 dangerouslySetInnerHTML={{ __html: title }} />

        <p className="" dangerouslySetInnerHTML={{ __html: introduction }} />

        <ul className="links">
          <li>
            <a
              href={buttonLink}
              className={`button button-primary  ${dynamicClass}`}
              title="This is a title"
              dangerouslySetInnerHTML={{ __html: buttonText }}
            />
          </li>
          <li>
            <a
              href={linkLink}
              className="standalone-link"
              title="This is a title"
              dangerouslySetInnerHTML={{
                __html: linkText,
              }}
            />
          </li>
        </ul>
      </div>
    </section>
  )
}
export default AdviceCard
